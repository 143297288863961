var data = {
    categories: [

        // START: Populație generală
        // START: Urban
        {
            name: 'populatie_generala',
            title: 'Populație generală',
            urban: [
                {
                    j: 'AB',
                    v: '188.681', 
                },
                {
                    j: 'AR',
                    v: '226.091',
                },
                {
                    j: 'AG',
                    v: '260.654',
                },
                {
                    j: 'BC',
                    v: '248.192',
                },
                {
                    j: 'BH',
                    v: '269.604',
                },
                {
                    j: 'BN',
                    v: '108.191',
                },
                {
                    j: 'BT',
                    v: '152.175',
                },
                {
                    j: 'BR',
                    v: '172.601',
                },
                {
                    j: 'BV',
                    v: '386.269',
                },
                {
                    j: 'B',
                    v: '1.823.526',
                },
                {
                    j: 'BZ',
                    v: '154.315',
                },
                {
                    j: 'CL',
                    v: '100.196',
                },
                {
                    j: 'CS',
                    v: '139.868',
                },
                {
                    j: 'CJ',
                    v: '458.465',
                },
                {
                    j: 'CT',
                    v: '447.496',
                },
                {
                    j: 'CV',
                    v: '94.064',
                },
                {
                    j: 'DB',
                    v: '136.080',
                },
                {
                    j: 'DJ',
                    v: '314.769',
                },
                {
                    j: 'GL',
                    v: '268.672',
                },
                {
                    j: 'GR',
                    v: '74.673',
                },
                {
                    j: 'GJ',
                    v: '137.138',
                },
                {
                    j: 'HR',
                    v: '126.006',
                },
                {
                    j: 'HD',
                    v: '275.469',
                },
                {
                    j: 'IL',
                    v: '112.464',
                },
                {
                    j: 'IS',
                    v: '371.561',
                },
                {
                    j: 'IF',
                    v: '225.863',
                },
                {
                    j: 'MM',
                    v: '257.967',
                },
                {
                    j: 'MH',
                    v: '103.495',
                },
                {
                    j: 'MS',
                    v: '259.098',
                },
                {
                    j: 'NT',
                    v: '152.817',
                },
                {
                    j: 'OT',
                    v: '150.099',
                },
                {
                    j: 'PH',
                    v: '340.137',
                },
                {
                    j: 'SJ',
                    v: '82.315',
                },
                {
                    j: 'SM',
                    v: '145.151',
                },
                {
                    j: 'SB',
                    v: '259.210',
                },
                {
                    j: 'SV',
                    v: '251.633',
                },
                {
                    j: 'TR',
                    v: '104.407',
                },
                {
                    j: 'TM',
                    v: '408.211',
                },
                {
                    j: 'TL',
                    v: '87.930',
                },
                {
                    j: 'VL',
                    v: '153.509',
                },
                {
                    j: 'VS',
                    v: '152.951',
                },
                {
                    j: 'VN',
                    v: '114.380',
                },
            ],
            //END: Urban

            //START: Rural
            rural: [
                {
                    j: 'AB',
                    v: '132.236',
                },
                {
                    j: 'AR',
                    v: '186.939',
                },
                {
                    j: 'AG',
                    v: '307.024',
                },
                {
                    j: 'BC',
                    v: '327.388',
                },
                {
                    j: 'BH',
                    v: '287.686',
                },
                {
                    j: 'BN',
                    v: '168.066',
                },
                {
                    j: 'BT',
                    v: '219.630',
                },
                {
                    j: 'BR',
                    v: '108.078',
                },
                {
                    j: 'BV',
                    v: '166.987',
                },
                {
                    j: 'B',
                    v: '',
                    customTitle: " "
                },
                {
                    j: 'BZ',
                    v: '249394',
                },
                {
                    j: 'CL',
                    v: '177.505',
                },
                {
                    j: 'CS',
                    v: '126.415',
                },
                {
                    j: 'CJ',
                    v: '251.819',
                },
                {
                    j: 'CT',
                    v: '220.569',
                },
                {
                    j: 'CV',
                    v: '105.986',
                },
                {
                    j: 'DB',
                    v: '347.022',
                },
                {
                    j: 'DJ',
                    v: '301.193',
                },
                {
                    j: 'GL',
                    v: '226.477',
                },
                {
                    j: 'GR',
                    v: '186.377',
                },
                {
                    j: 'GJ',
                    v: '170.829',
                },
                {
                    j: 'HR',
                    v: '173.069',
                },
                {
                    j: 'HD',
                    v: '98.300',
                },
                {
                    j: 'IL',
                    v: '139.453',
                },
                {
                    j: 'IS',
                    v: '423.191',
                },
                {
                    j: 'IF',
                    v: '277.668',
                },
                {
                    j: 'MM',
                    v: '196.903',
                },
                {
                    j: 'MH',
                    v: '132.965',
                },
                {
                    j: 'MS',
                    v: '270.227',
                },
                {
                    j: 'NT',
                    v: '280.169',
                },
                {
                    j: 'OT',
                    v: '235.676',
                },
                {
                    j: 'PH',
                    v: '363.231',
                },
                {
                    j: 'SJ',
                    v: '126.729',
                },
                {
                    j: 'SM',
                    v: '184.121',
                },
                {
                    j: 'SB',
                    v: '141.000',
                },
                {
                    j: 'SV',
                    v: '368.459',
                },
                {
                    j: 'TR',
                    v: '219.771',
                },
                {
                    j: 'TM',
                    v: '297.289',
                },
                {
                    j: 'TL',
                    v: '101.777',
                },
                {
                    j: 'VL',
                    v: '192.405',
                },
                {
                    j: 'VS',
                    v: '215.299',
                },
                {
                    j: 'VN',
                    v: '199.947',
                },
            ]
        },
        //END: Rural
        // END: Populație generală

        // START: Sărăcie și excluziune socială
        {
            name: 'saracie_si_excluziune_sociala',
            title: 'Sărăcie și excluziune socială',
            subcategories: [
                {
                    //START: Urban ajutor social
                    name: 'nr_pers_ajutor_social',
                    title: 'Număr persoane care primesc ajutor social',
                    urban: [
                        {
                            j: 'AB',
                            v: '702',
                        },
                        {
                            j: 'AR',
                            v: '473',
                        },
                        {
                            j: 'AG',
                            v: '279',
                        },
                        {
                            j: 'BC',
                            v: '1.375',
                        },
                        {
                            j: 'BH',
                            v: '463',
                        },
                        {
                            j: 'BN',
                            v: '166',
                        },
                        {
                            j: 'BT',
                            v: '682',
                        },
                        {
                            j: 'BR',
                            v: '407',
                        },
                        {
                            j: 'BV',
                            v: '628',
                        },
                        {
                            j: 'B',
                            v: '227',
                        },
                        {
                            j: 'BZ',
                            v: '1.154',
                        },
                        {
                            j: 'CL',
                            v: '572',
                        },
                        {
                            j: 'CS',
                            v: '503',
                        },
                        {
                            j: 'CJ',
                            v: '524',
                        },
                        {
                            j: 'CT',
                            v: '929',
                        },
                        {
                            j: 'CV',
                            v: '272',
                        },
                        {
                            j: 'DB',
                            v: '629',
                        },
                        {
                            j: 'DJ',
                            v: '839',
                        },
                        {
                            j: 'GL',
                            v: '760',
                        },
                        {
                            j: 'GR',
                            v: '445',
                        },
                        {
                            j: 'GJ',
                            v: '518',
                        },
                        {
                            j: 'HR',
                            v: '527',
                        },
                        {
                            j: 'HD',
                            v: '1.147',
                        },
                        {
                            j: 'IL',
                            v: 582,
                        },
                        {
                            j: 'IS',
                            v: 626,
                        },
                        {
                            j: 'IF',
                            v: 92,
                        },
                        {
                            j: 'MM',
                            v: '1.167',
                        },
                        {
                            j: 'MH',
                            v: 343,
                        },
                        {
                            j: 'MS',
                            v: 839,
                        },
                        {
                            j: 'NT',
                            v: 529,
                        },
                        {
                            j: 'OT',
                            v: 675,
                        },
                        {
                            j: 'PH',
                            v: 669,
                        },
                        {
                            j: 'SJ',
                            v: 312,
                        },
                        {
                            j: 'SM',
                            v: 480,
                        },
                        {
                            j: 'SB',
                            v: 525,
                        },
                        {
                            j: 'SV',
                            v: '1.158',
                        },
                        {
                            j: 'TR',
                            v: '1.141',
                        },
                        {
                            j: 'TM',
                            v: 358,
                        },
                        {
                            j: 'TL',
                            v: 207,
                        },
                        {
                            j: 'VL',
                            v: 421,
                        },
                        {
                            j: 'VS',
                            v: 696,
                        },
                        {
                            j: 'VN',
                            v: 576,
                        },
                    ],
                    //END: Urban ajutor social
                    
                    //START: Rural ajutor social
                    rural: [
                        {
                            j: 'AB',
                            v: '2.596',
                        },
                        {
                            j: 'AR',
                            v: '1.495',
                        },
                        {
                            j: 'AG',
                            v: '4.956',
                        },
                        {
                            j: 'BC',
                            v: '7.539',
                        },
                        {
                            j: 'BH',
                            v: '2.478',
                        },
                        {
                            j: 'BN',
                            v: '1.648',
                        },
                        {
                            j: 'BT',
                            v: '2.097',
                        },
                        {
                            j: 'BR',
                            v: '2.361',
                        },
                        {
                            j: 'BV',
                            v: '2.713',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '6.762',
                        },
                        {
                            j: 'CL',
                            v: '2.033',
                        },
                        {
                            j: 'CS',
                            v: '1.322',
                        },
                        {
                            j: 'CJ',
                            v: '1.704',
                        },
                        {
                            j: 'CT',
                            v: '2.065',
                        },
                        {
                            j: 'CV',
                            v: '1.325',
                        },
                        {
                            j: 'DB',
                            v: '3.911',
                        },
                        {
                            j: 'DJ',
                            v: '9.207',
                        },
                        {
                            j: 'GL',
                            v: '6.429',
                        },
                        {
                            j: 'GR',
                            v: '1.905',
                        },
                        {
                            j: 'GJ',
                            v: '2.327',
                        },
                        {
                            j: 'HR',
                            v: '1.816',
                        },
                        {
                            j: 'HD',
                            v: 896,
                        },
                        {
                            j: 'IL',
                            v: '2.063',
                        },
                        {
                            j: 'IS',
                            v: '5.294',
                        },
                        {
                            j: 'IF',
                            v: 828,
                        },
                        {
                            j: 'MM',
                            v: '2.658',
                        },
                        {
                            j: 'MH',
                            v: '3.756',
                        },
                        {
                            j: 'MS',
                            v: '4.422',
                        },
                        {
                            j: 'NT',
                            v: '4.310',
                        },
                        {
                            j: 'OT',
                            v: '4.279',
                        },
                        {
                            j: 'PH',
                            v: '2.662',
                        },
                        {
                            j: 'SJ',
                            v: '2.009',
                        },
                        {
                            j: 'SM',
                            v: '2.715',
                        },
                        {
                            j: 'SB',
                            v: '2.212',
                        },
                        {
                            j: 'SV',
                            v: '4.664',
                        },
                        {
                            j: 'TR',
                            v: '6.178',
                        },
                        {
                            j: 'TM',
                            v: 948,
                        },
                        {
                            j: 'TL',
                            v: '1.441',
                        },
                        {
                            j: 'VL',
                            v: '2.298',
                        },
                        {
                            j: 'VS',
                            v: '6.606',
                        },
                        {
                            j: 'VN',
                            v: '4.500',
                        },
                    ]
                    //END: Rural ajutor social
                },
                //START: nr someri
                {
                    //START: Urban numar someri
                    name: 'nr_someri',
                    title: 'Număr șomeri',
                    urban: [
                        {
                            j: 'AB',
                            v: '1.587',
                        },
                        {
                            j: 'AR',
                            v: '1.111',
                        },
                        {
                            j: 'AG',
                            v: '1.500',
                        },
                        {
                            j: 'BC',
                            v: '2.126',
                        },
                        {
                            j: 'BH',
                            v: '1.059',
                        },
                        {
                            j: 'BN',
                            v: '1.269',
                        },
                        {
                            j: 'BT',
                            v: 888,
                        },
                        {
                            j: 'BR',
                            v: 863,
                        },
                        {
                            j: 'BV',
                            v: '1.595',
                        },
                        {
                            j: 'B',
                            v: '14.004',
                        },
                        {
                            j: 'BZ',
                            v: '1.573',
                        },
                        {
                            j: 'CL',
                            v: 786,
                        },
                        {
                            j: 'CS',
                            v: 967,
                        },
                        {
                            j: 'CJ',
                            v: '1.806',
                        },
                        {
                            j: 'CT',
                            v: '3.008',
                        },
                        {
                            j: 'CV',
                            v: 888,
                        },
                        {
                            j: 'DB',
                            v: 742,
                        },
                        {
                            j: 'DJ',
                            v: '2.254',
                        },
                        {
                            j: 'GL',
                            v: '1.762',
                        },
                        {
                            j: 'GR',
                            v: 471,
                        },
                        {
                            j: 'GJ',
                            v: '1.273',
                        },
                        {
                            j: 'HR',
                            v: '1.341',
                        },
                        {
                            j: 'HD',
                            v: '4.199',
                        },
                        {
                            j: 'IL',
                            v: '1.127',
                        },
                        {
                            j: 'IS',
                            v: '1.242',
                        },
                        {
                            j: 'IF',
                            v: 215,
                        },
                        {
                            j: 'MM',
                            v: '2.031',
                        },
                        {
                            j: 'MH',
                            v: 991,
                        },
                        {
                            j: 'MS',
                            v: '1.421',
                        },
                        {
                            j: 'NT',
                            v: '1.071',
                        },
                        {
                            j: 'OT',
                            v: '1.548',
                        },
                        {
                            j: 'PH',
                            v: '1.989',
                        },
                        {
                            j: 'SJ',
                            v: 807,
                        },
                        {
                            j: 'SM',
                            v: 815,
                        },
                        {
                            j: 'SB',
                            v: '1.253',
                        },
                        {
                            j: 'SV',
                            v: '2.743',
                        },
                        {
                            j: 'TR',
                            v: '1.774',
                        },
                        {
                            j: 'TM',
                            v: '1.152',
                        },
                        {
                            j: 'TL',
                            v: 485,
                        },
                        {
                            j: 'VL',
                            v: '1.529',
                        },
                        {
                            j: 'VS',
                            v: '1.226',
                        },
                        {
                            j: 'VN',
                            v: 914,
                        },
                    ],
                    //END: Urban numar someri
                    
                    //START: Rural numar someri
                    rural: [
                        {
                            j: 'AB',
                            v: '3.453',
                        },
                        {
                            j: 'AR',
                            v: '1.838',
                        },
                        {
                            j: 'AG',
                            v: '6.754',
                        },
                        {
                            j: 'BC',
                            v: '4.752',
                        },
                        {
                            j: 'BH',
                            v: '2.464',
                        },
                        {
                            j: 'BN',
                            v: '2.662',
                        },
                        {
                            j: 'BT',
                            v: '1.947',
                        },
                        {
                            j: 'BR',
                            v: '2.780',
                        },
                        {
                            j: 'BV',
                            v: '3.528',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '6.565',
                        },
                        {
                            j: 'CL',
                            v: '2.057',
                        },
                        {
                            j: 'CS',
                            v: '1.425',
                        },
                        {
                            j: 'CJ',
                            v: '2.636',
                        },
                        {
                            j: 'CT',
                            v: '3.446',
                        },
                        {
                            j: 'CV',
                            v: '2.381',
                        },
                        {
                            j: 'DB',
                            v: '3.871',
                        },
                        {
                            j: 'DJ',
                            v: '13.121',
                        },
                        {
                            j: 'GL',
                            v: '8.864',
                        },
                        {
                            j: 'GR',
                            v: '1.508',
                        },
                        {
                            j: 'GJ',
                            v: '3.022',
                        },
                        {
                            j: 'HR',
                            v: '3.298',
                        },
                        {
                            j: 'HD',
                            v: '2.032',
                        },
                        {
                            j: 'IL',
                            v: '2.989',
                        },
                        {
                            j: 'IS',
                            v: '6.745',
                        },
                        {
                            j: 'IF',
                            v: 552,
                        },
                        {
                            j: 'MM',
                            v: '1.680',
                        },
                        {
                            j: 'MH',
                            v: '4.650',
                        },
                        {
                            j: 'MS',
                            v: '3.767',
                        },
                        {
                            j: 'NT',
                            v: '5.511',
                        },
                        {
                            j: 'OT',
                            v: '6.111',
                        },
                        {
                            j: 'PH',
                            v: '3.926',
                        },
                        {
                            j: 'SJ',
                            v: '3.545',
                        },
                        {
                            j: 'SM',
                            v: '3.782',
                        },
                        {
                            j: 'SB',
                            v: '2.419',
                        },
                        {
                            j: 'SV',
                            v: '7.658',
                        },
                        {
                            j: 'TR',
                            v: '6.866',
                        },
                        {
                            j: 'TM',
                            v: '1.440',
                        },
                        {
                            j: 'TL',
                            v: '1.783',
                        },
                        {
                            j: 'VL',
                            v: '3.301',
                        },
                        {
                            j: 'VS',
                            v: '7.927',
                        },
                        {
                            j: 'VN',
                            v: '4.297',
                        },
                    ]
                    //END: Rural numar someri
                },
                {
                    //START: Urban numar emigranti
                    name: 'nr_emigranti_temporar',
                    title: 'Număr emigranți temporar',
                    urban: [
                        {
                            j: 'AB',
                            v: '1.763',
                        },
                        {
                            j: 'AR',
                            v: '2.129',
                        },
                        {
                            j: 'AG',
                            v: '2.431',
                        },
                        {
                            j: 'BC',
                            v: '2.355',
                        },
                        {
                            j: 'BH',
                            v: '2.448',
                        },
                        {
                            j: 'BN',
                            v: 927,
                        },
                        {
                            j: 'BT',
                            v: '1.525',
                        },
                        {
                            j: 'BR',
                            v: '1.773',
                        },
                        {
                            j: 'BV',
                            v: '3.477',
                        },
                        {
                            j: 'B',
                            v: '16.162',
                        },
                        {
                            j: 'BZ',
                            v: '1.542',
                        },
                        {
                            j: 'CL',
                            v: '1.012',
                        },
                        {
                            j: 'CS',
                            v: '1.473',
                        },
                        {
                            j: 'CJ',
                            v: '4.061',
                        },
                        {
                            j: 'CT',
                            v: '4.323',
                        },
                        {
                            j: 'CV',
                            v: 903,
                        },
                        {
                            j: 'DB',
                            v: '1.313',
                        },
                        {
                            j: 'DJ',
                            v: '3.069',
                        },
                        {
                            j: 'GL',
                            v: '2.605',
                        },
                        {
                            j: 'GR',
                            v: 769,
                        },
                        {
                            j: 'GJ',
                            v: '1.375',
                        },
                        {
                            j: 'HR',
                            v: '1.174',
                        },
                        {
                            j: 'HD',
                            v: '2.810',
                        },
                        {
                            j: 'IL',
                            v: '1.114',
                        },
                        {
                            j: 'IS',
                            v: '3.132',
                        },
                        {
                            j: 'IF',
                            v: '1.502',
                        },
                        {
                            j: 'MM',
                            v: '2.510',
                        },
                        {
                            j: 'MH',
                            v: '1.119',
                        },
                        {
                            j: 'MS',
                            v: '2.436',
                        },
                        {
                            j: 'NT',
                            v: '1.505',
                        },
                        {
                            j: 'OT',
                            v: '1.580',
                        },
                        {
                            j: 'PH',
                            v: '3.285',
                        },
                        {
                            j: 'SJ',
                            v: 764,
                        },
                        {
                            j: 'SM',
                            v: '1.369',
                        },
                        {
                            j: 'SB',
                            v: '2.281',
                        },
                        {
                            j: 'SV',
                            v: '2.312',
                        },
                        {
                            j: 'TR',
                            v: '1.097',
                        },
                        {
                            j: 'TM',
                            v: '3.813',
                        },
                        {
                            j: 'TL',
                            v: 901,
                        },
                        {
                            j: 'VL',
                            v: '1.441',
                        },
                        {
                            j: 'VS',
                            v: '1.360',
                        },
                        {
                            j: 'VN',
                            v: '1.116',
                        },
                    ],
                    //END: Urban numar emigranti

                    //START: Rural numar emigranti
                    rural: [
                        {
                            j: 'AB',
                            v: '1.529',
                        },
                        {
                            j: 'AR',
                            v: '2.043',
                        },
                        {
                            j: 'AG',
                            v: '3.295',
                        },
                        {
                            j: 'BC',
                            v: '3.540',
                        },
                        {
                            j: 'BH',
                            v: '3.064',
                        },
                        {
                            j: 'BN',
                            v: '1.852',
                        },
                        {
                            j: 'BT',
                            v: '2.388',
                        },
                        {
                            j: 'BR',
                            v: '1.223',
                        },
                        {
                            j: 'BV',
                            v: '1.678',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '2.846',
                        },
                        {
                            j: 'CL',
                            v: '2.053',
                        },
                        {
                            j: 'CS',
                            v: '1.480',
                        },
                        {
                            j: 'CJ',
                            v: '2.498',
                        },
                        {
                            j: 'CT',
                            v: '2.448',
                        },
                        {
                            j: 'CV',
                            v: '1.178',
                        },
                        {
                            j: 'DB',
                            v: '3.714',
                        },
                        {
                            j: 'DJ',
                            v: '3.434',
                        },
                        {
                            j: 'GL',
                            v: '2.502',
                        },
                        {
                            j: 'GR',
                            v: '2.049',
                        },
                        {
                            j: 'GJ',
                            v: '1.901',
                        },
                        {
                            j: 'HR',
                            v: '1.945',
                        },
                        {
                            j: 'HD',
                            v: '1.025',
                        },
                        {
                            j: 'IL',
                            v: '1.672',
                        },
                        {
                            j: 'IS',
                            v: '4.175',
                        },
                        {
                            j: 'IF',
                            v: '2.334',
                        },
                        {
                            j: 'MM',
                            v: '2.155',
                        },
                        {
                            j: 'MH',
                            v: '1.466',
                        },
                        {
                            j: 'MS',
                            v: '3.005',
                        },
                        {
                            j: 'NT',
                            v: '3.189',
                        },
                        {
                            j: 'OT',
                            v: '2.707',
                        },
                        {
                            j: 'PH',
                            v: '4.056',
                        },
                        {
                            j: 'SJ',
                            v: '1.369',
                        },
                        {
                            j: 'SM',
                            v: '2.034',
                        },
                        {
                            j: 'SB',
                            v: '1.463',
                        },
                        {
                            j: 'SV',
                            v: '3.766',
                        },
                        {
                            j: 'TR',
                            v: '2.680',
                        },
                        {
                            j: 'TM',
                            v: '2.795',
                        },
                        {
                            j: 'TL',
                            v: '1.214',
                        },
                        {
                            j: 'VL',
                            v: '2.085',
                        },
                        {
                            j: 'VS',
                            v: '2.457',
                        },
                        {
                            j: 'VN',
                            v: '2.258',
                        },
                    ]
                    //END: Rural numar emigranti
                },
            ],
            
        },
        // END: Sărăcie și excluziune socială

        //START: Sanatate
        {
            name:"sanatate",
            title: "Sănătate",
            subcategories: [
                //START: numar medici
                {
                    name: "nr_medici",
                    title: "Număr medici",
 
                    urban: [
                        {
                            j: 'AB',
                            v: '589',
                            i: 'din care medici de familie <span>70</span>',
                        },
                        {
                            j: 'AR',
                            v: '1.004',
                            i: 'din care medici de familie <span>208</span>',
                        },
                        {
                            j: 'AG',
                            v: '1.217',
                            i: 'din care medici de familie <span>176</span>',
                        },
                        {
                            j: 'BC',
                            v: '971',
                            i: 'din care medici de familie <span>146</span>',
                        },
                        {
                            j: 'BH',
                            v: '2.042',
                            i: 'din care medici de familie <span>269</span>',
                        },
                        {
                            j: 'BN',
                            v: '407',
                            i: 'din care medici de familie <span>62</span>',
                        },
                        {
                            j: 'BT',
                            v: '532',
                            i: 'din care medici de familie <span>88</span>',
                        },
                        {
                            j: 'BR',
                            v: '487',
                            i: 'din care medici de familie <span>101</span>',
                        },
                        {
                            j: 'BV',
                            v: '1.773',
                            i: 'din care medici de familie <span>327</span>',
                        },
                        {
                            j: 'B',
                            v: '14.914',
                            i: 'din care medici de familie <span>2.007</span>',
                        },
                        {
                            j: 'BZ',
                            v: '475',
                            i: 'din care medici de familie <span>101</span>',
                        },
                        {
                            j: 'CL',
                            v: '242',
                            i: 'din care medici de familie <span>44</span>',
                        },
                        {
                            j: 'CS',
                            v: '511',
                            i: 'din care medici de familie <span>98</span>',
                        },
                        {
                            j: 'CJ',
                            v: '3.960',
                            i: 'din care medici de familie <span>345</span>',
                        },
                        {
                            j: 'CT',
                            v: '2.235',
                            i: 'din care medici de familie <span>331</span>',
                        },
                        {
                            j: 'CV',
                            v: '412',
                            i: 'din care medici de familie <span>57</span>',
                        },
                        {
                            j: 'DB',
                            v: '396',
                            i: 'din care medici de familie <span>77</span>',
                        },
                        {
                            j: 'DJ',
                            v: '3.058',
                            i: 'din care medici de familie <span>254</span>',
                        },
                        {
                            j: 'GL',
                            v: '1.039',
                            i: 'din care medici de familie <span>200</span>',
                        },
                        {
                            j: 'GR',
                            v: '210',
                            i: 'din care medici de familie <span>35</span>',
                        },
                        {
                            j: 'GJ',
                            v: '674',
                            i: 'din care medici de familie <span>91</span>',
                        },
                        {
                            j: 'HR',
                            v: '513',
                            i: 'din care medici de familie <span>77</span>',
                        },
                        {
                            j: 'HD',
                            v: '1.099',
                            i: 'din care medici de familie <span>190</span>',
                        },
                        {
                            j: 'IL',
                            v: '284',
                            i: 'din care medici de familie <span>56</span>',
                        },
                        {
                            j: 'IS',
                            v: '4.265',
                            i: 'din care medici de familie <span>351</span>',
                        },
                        {
                            j: 'IF',
                            v: '599',
                            i: 'din care medici de familie <span>86</span>',
                        },
                        {
                            j: 'MM',
                            v: '828',
                            i: 'din care medici de familie <span>150</span>',
                        },
                        {
                            j: 'MH',
                            v: '428',
                            i: 'din care medici de familie <span>87</span>',
                        },
                        {
                            j: 'MS',
                            v: '2.650',
                            i: 'din care medici de familie <span>251</span>',
                        },
                        {
                            j: 'NT',
                            v: '689',
                            i: 'din care medici de familie <span>112</span>',
                        },
                        {
                            j: 'OT',
                            v: '595',
                            i: 'din care medici de familie <span>104</span>',
                        },
                        {
                            j: 'PH',
                            v: '972',
                            i: 'din care medici de familie <span>218</span>',
                        },
                        {
                            j: 'SJ',
                            v: '390',
                            i: 'din care medici de familie <span>56</span>',
                        },
                        {
                            j: 'SM',
                            v: '521',
                            i: 'din care medici de familie <span>102</span>',
                        },
                        {
                            j: 'SB',
                            v: '1.384',
                            i: 'din care medici de familie <span>242</span>',
                        },
                        {
                            j: 'SV',
                            v: '786',
                            i: 'din care medici de familie <span>131</span>',
                        },
                        {
                            j: 'TR',
                            v: '375',
                            i: 'din care medici de familie <span>73</span>',
                        },
                        {
                            j: 'TM',
                            v: '4.866',
                            i: 'din care medici de familie <span>439</span>',
                        },
                        {
                            j: 'TL',
                            v: '282',
                            i: 'din care medici de familie <span>48</span>',
                        },
                        {
                            j: 'VL',
                            v: '616',
                            i: 'din care medici de familie <span>97</span>',
                        },
                        {
                            j: 'VS',
                            v: '455',
                            i: 'din care medici de familie <span>74</span>',
                        },
                        {
                            j: 'VN',
                            v: '408',
                            i: 'din care medici de familie <span>67</span>',
                        },
                    ],
                    rural:[
                        {
                            j: 'AB',
                            v: '98',
                            i: 'din care medici de familie <span>96</span>',
                        },
                        {
                            j: 'AR',
                            v: '162',
                            i: 'din care medici de familie <span>137</span>',
                        },
                        {
                            j: 'AG',
                            v: '253',
                            i: 'din care medici de familie <span>174</span>',
                        },
                        {
                            j: 'BC',
                            v: '137',
                            i: 'din care medici de familie <span>118</span>',
                        },
                        {
                            j: 'BH',
                            v: '291',
                            i: 'din care medici de familie <span>187</span>',
                        },
                        {
                            j: 'BN',
                            v: '77',
                            i: 'din care medici de familie <span>75</span>',
                        },
                        {
                            j: 'BT',
                            v: '108',
                            i: 'din care medici de familie <span>86</span>',
                        },
                        {
                            j: 'BR',
                            v: '49',
                            i: 'din care medici de familie <span>45</span>',
                        },
                        {
                            j: 'BV',
                            v: '83',
                            i: 'din care medici de familie <span>69</span>',
                        },
                        {
                            j: 'B',
                            v: '',
                            i: '',
                            customTitle: ' ',
                        },
                        {
                            j: 'BZ',
                            v: '151',
                            i: 'din care medici de familie <span>104</span>',
                        },
                        {
                            j: 'CL',
                            v: '82',
                            i: 'din care medici de familie <span>72</span>',
                        },
                        {
                            j: 'CS',
                            v: '63',
                            i: 'din care medici de familie <span>57</span>',
                        },
                        {
                            j: 'CJ',
                            v: '152',
                            i: 'din care medici de familie <span>106</span>',
                        },
                        {
                            j: 'CT',
                            v: '157',
                            i: 'din care medici de familie <span>116</span>',
                        },
                        {
                            j: 'CV',
                            v: '41',
                            i: 'din care medici de familie <span>41</span>',
                        },
                        {
                            j: 'DB',
                            v: '156',
                            i: 'din care medici de familie <span>141</span>',
                        },
                        {
                            j: 'DJ',
                            v: '330',
                            i: 'din care medici de familie <span>234</span>',
                        },
                        {
                            j: 'GL',
                            v: '91',
                            i: 'din care medici de familie <span>89</span>',
                        },
                        {
                            j: 'GR',
                            v: '96',
                            i: 'din care medici de familie <span>67</span>',
                        },
                        {
                            j: 'GJ',
                            v: '118',
                            i: 'din care medici de familie <span>100</span>',
                        },
                        {
                            j: 'HR',
                            v: '99',
                            i: 'din care medici de familie <span>90</span>',
                        },
                        {
                            j: 'HD',
                            v: '67',
                            i: 'din care medici de familie <span>54</span>',
                        },
                        {
                            j: 'IL',
                            v: '55',
                            i: 'din care medici de familie <span>54</span>',
                        },
                        {
                            j: 'IS',
                            v: '287',
                            i: 'din care medici de familie <span>221</span>',
                        },
                        {
                            j: 'IF',
                            v: '353',
                            i: 'din care medici de familie <span>115</span>',
                        },
                        {
                            j: 'MM',
                            v: '101',
                            i: 'din care medici de familie <span>97</span>',
                        },
                        {
                            j: 'MH',
                            v: '66',
                            i: 'din care medici de familie <span>64</span>',
                        },
                        {
                            j: 'MS',
                            v: '181',
                            i: 'din care medici de familie <span>143</span>',
                        },
                        {
                            j: 'NT',
                            v: '170',
                            i: 'din care medici de familie <span>129</span>',
                        },
                        {
                            j: 'OT',
                            v: '147',
                            i: 'din care medici de familie <span>139</span>',
                        },
                        {
                            j: 'PH',
                            v: '218',
                            i: 'din care medici de familie <span>165</span>',
                        },
                        {
                            j: 'SJ',
                            v: '64',
                            i: 'din care medici de familie <span>58</span>',
                        },
                        {
                            j: 'SM',
                            v: '81',
                            i: 'din care medici de familie <span>76</span>',
                        },
                        {
                            j: 'SB',
                            v: '71',
                            i: 'din care medici de familie <span>68</span>',
                        },
                        {
                            j: 'SV',
                            v: '149',
                            i: 'din care medici de familie <span>137</span>',
                        },
                        {
                            j: 'TR',
                            v: '124',
                            i: 'din care medici de familie <span>108</span>',
                        },
                        {
                            j: 'TM',
                            v: '299',
                            i: 'din care medici de familie <span>191</span>',
                        },
                        {
                            j: 'TL',
                            v: '42',
                            i: 'din care medici de familie <span>39</span>',
                        },
                        {
                            j: 'VL',
                            v: '128',
                            i: 'din care medici de familie <span>104</span>',
                        },
                        {
                            j: 'VS',
                            v: '80',
                            i: 'din care medici de familie <span>75</span>',
                        },
                        {
                            j: 'VN',
                            v: '109',
                            i: 'din care medici de familie <span>85</span>',
                        },
                    ],
                },
                //END: numar medici

                //START: medici stomatologi
                {
                    name: "medici_stomatologi",
                    title: "Număr medici stomatologi",
                    //START: medici stomatologi urban
                    urban: [
                        {
                            j: 'AB',
                            v: 134,
                        },
                        {
                            j: 'AR',
                            v: 365,
                        },
                        {
                            j: 'AG',
                            v: 351,
                        },
                        {
                            j: 'BC',
                            v: 305,
                        },
                        {
                            j: 'BH',
                            v: 650,
                        },
                        {
                            j: 'BN',
                            v: 196,
                        },
                        {
                            j: 'BT',
                            v: 148,
                        },
                        {
                            j: 'BR',
                            v: 217,
                        },
                        {
                            j: 'BV',
                            v: 409,
                        },
                        {
                            j: 'B',
                            v: '3.681',
                        },
                        {
                            j: 'BZ',
                            v: 157,
                        },
                        {
                            j: 'CL',
                            v: 54,
                        },
                        {
                            j: 'CS',
                            v: 142,
                        },
                        {
                            j: 'CJ',
                            v: '1.054',
                        },
                        {
                            j: 'CT',
                            v: 672,
                        },
                        {
                            j: 'CV',
                            v: 88,
                        },
                        {
                            j: 'DB',
                            v: 179,
                        },
                        {
                            j: 'DJ',
                            v: 711,
                        },
                        {
                            j: 'GL',
                            v: 384,
                        },
                        {
                            j: 'GR',
                            v: 33,
                        },
                        {
                            j: 'GJ',
                            v: 152,
                        },
                        {
                            j: 'HR',
                            v: 138,
                        },
                        {
                            j: 'HD',
                            v: 248,
                        },
                        {
                            j: 'IL',
                            v: 66,
                        },
                        {
                            j: 'IS',
                            v: 660,
                        },
                        {
                            j: 'IF',
                            v: 116,
                        },
                        {
                            j: 'MM',
                            v: 327,
                        },
                        {
                            j: 'MH',
                            v: 133,
                        },
                        {
                            j: 'MS',
                            v: 697,
                        },
                        {
                            j: 'NT',
                            v: 261,
                        },
                        {
                            j: 'OT',
                            v: 72,
                        },
                        {
                            j: 'PH',
                            v: 423,
                        },
                        {
                            j: 'SJ',
                            v: 119,
                        },
                        {
                            j: 'SM',
                            v: 191,
                        },
                        {
                            j: 'SB',
                            v: 537,
                        },
                        {
                            j: 'SV',
                            v: 320,
                        },
                        {
                            j: 'TR',
                            v: 99,
                        },
                        {
                            j: 'TM',
                            v: '1.201',
                        },
                        {
                            j: 'TL',
                            v: 89,
                        },
                        {
                            j: 'VL',
                            v: 235,
                        },
                        {
                            j: 'VS',
                            v: 153,
                        },
                        {
                            j: 'VN',
                            v: 127,
                        },
                    ],
                    //END: Medici stomatologi urban

                    //START: medici stomatologi rural
                    rural: [
                        {
                            j: 'AB',
                            v: 31,
                        },
                        {
                            j: 'AR',
                            v: 34,
                        },
                        {
                            j: 'AG',
                            v: 50,
                        },
                        {
                            j: 'BC',
                            v: 46,
                        },
                        {
                            j: 'BH',
                            v: 115,
                        },
                        {
                            j: 'BN',
                            v: 55,
                        },
                        {
                            j: 'BT',
                            v: 26,
                        },
                        {
                            j: 'BR',
                            v: 12,
                        },
                        {
                            j: 'BV',
                            v: 35,
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: 44,
                        },
                        {
                            j: 'CL',
                            v: 29,
                        },
                        {
                            j: 'CS',
                            v: 20,
                        },
                        {
                            j: 'CJ',
                            v: 112,
                        },
                        {
                            j: 'CT',
                            v: 87,
                        },
                        {
                            j: 'CV',
                            v: 24,
                        },
                        {
                            j: 'DB',
                            v: 57,
                        },
                        {
                            j: 'DJ',
                            v: 90,
                        },
                        {
                            j: 'GL',
                            v: 51,
                        },
                        {
                            j: 'GR',
                            v: 41,
                        },
                        {
                            j: 'GJ',
                            v: 34,
                        },
                        {
                            j: 'HR',
                            v: 55,
                        },
                        {
                            j: 'HD',
                            v: 19,
                        },
                        {
                            j: 'IL',
                            v: 12,
                        },
                        {
                            j: 'IS',
                            v: 142,
                        },
                        {
                            j: 'IF',
                            v: 126,
                        },
                        {
                            j: 'MM',
                            v: 48,
                        },
                        {
                            j: 'MH',
                            v: 17,
                        },
                        {
                            j: 'MS',
                            v: 106,
                        },
                        {
                            j: 'NT',
                            v: 79,
                        },
                        {
                            j: 'OT',
                            v: 11,
                        },
                        {
                            j: 'PH',
                            v: 95,
                        },
                        {
                            j: 'SJ',
                            v: 38,
                        },
                        {
                            j: 'SM',
                            v: 29,
                        },
                        {
                            j: 'SB',
                            v: 55,
                        },
                        {
                            j: 'SV',
                            v: 99,
                        },
                        {
                            j: 'TR',
                            v: 25,
                        },
                        {
                            j: 'TM',
                            v: 103,
                        },
                        {
                            j: 'TL',
                            v: 19,
                        },
                        {
                            j: 'VL',
                            v: 45,
                        },
                        {
                            j: 'VS',
                            v: 12,
                        },
                        {
                            j: 'VN',
                            v: 33,
                        },
                    ]
                    //END: medici stomatologi rural

                },
                //END: medici stomatologi

                //START: Număr unități sanitare
                {
                    name: "nr_unit_sanitare",
                    title: "Număr unități sanitare",
                    subcategories: [
                        {
                            name: "spitale",
                            title: "Spitale",

                            //START: spitale urban
                            urban: [
                                {
                                    j: 'AB',
                                    v: 11,
                                },
                                {
                                    j: 'AR',
                                    v: 10,
                                },
                                {
                                    j: 'AG',
                                    v: 12,
                                },
                                {
                                    j: 'BC',
                                    v: 14,
                                },
                                {
                                    j: 'BH',
                                    v: 13,
                                },
                                {
                                    j: 'BN',
                                    v: 4,
                                },
                                {
                                    j: 'BT',
                                    v: 4,
                                },
                                {
                                    j: 'BR',
                                    v: 5,
                                },
                                {
                                    j: 'BV',
                                    v: 23,
                                },
                                {
                                    j: 'B',
                                    v: 72,
                                },
                                {
                                    j: 'BZ',
                                    v: 5,
                                },
                                {
                                    j: 'CL',
                                    v: 5,
                                },
                                {
                                    j: 'CS',
                                    v: 6,
                                },
                                {
                                    j: 'CJ',
                                    v: 35,
                                },
                                {
                                    j: 'CT',
                                    v: 19,
                                },
                                {
                                    j: 'CV',
                                    v: 4,
                                },
                                {
                                    j: 'DB',
                                    v: 4,
                                },
                                {
                                    j: 'DJ',
                                    v: 22,
                                },
                                {
                                    j: 'GL',
                                    v: 10,
                                },
                                {
                                    j: 'GR',
                                    v: 3,
                                },
                                {
                                    j: 'GJ',
                                    v: 7,
                                },
                                {
                                    j: 'HR',
                                    v: 4,
                                },
                                {
                                    j: 'HD',
                                    v: 9,
                                },
                                {
                                    j: 'IL',
                                    v: 4,
                                },
                                {
                                    j: 'IS',
                                    v: 25,
                                },
                                {
                                    j: 'IF',
                                    v: 6,
                                },
                                {
                                    j: 'MM',
                                    v: 12,
                                },
                                {
                                    j: 'MH',
                                    v: 4,
                                },
                                {
                                    j: 'MS',
                                    v: 15,
                                },
                                {
                                    j: 'NT',
                                    v: 6,
                                },
                                {
                                    j: 'OT',
                                    v: 5,
                                },
                                {
                                    j: 'PH',
                                    v: 20,
                                },
                                {
                                    j: 'SJ',
                                    v: 5,
                                },
                                {
                                    j: 'SM',
                                    v: 6,
                                },
                                {
                                    j: 'SB',
                                    v: 10,
                                },
                                {
                                    j: 'SV',
                                    v: 11,
                                },
                                {
                                    j: 'TR',
                                    v: 6,
                                },
                                {
                                    j: 'TM',
                                    v: 20,
                                },
                                {
                                    j: 'TL',
                                    v: 4,
                                },
                                {
                                    j: 'VL',
                                    v: 9,
                                },
                                {
                                    j: 'VS',
                                    v: 5,
                                },
                                {
                                    j: 'VN',
                                    v: 5,
                                },
                            ],
                            //END: spitale urban

                            //START: spitale rural
                            rural: [
                                {
                                    j: 'AB',
                                    v: '0',
                                },
                                {
                                    j: 'AR',
                                    v: '2',
                                },
                                {
                                    j: 'AG',
                                    v: '5',
                                },
                                {
                                    j: 'BC',
                                    v: '2',
                                },
                                {
                                    j: 'BH',
                                    v: '1',
                                },
                                {
                                    j: 'BN',
                                    v: '0',
                                },
                                {
                                    j: 'BT',
                                    v: '0',
                                },
                                {
                                    j: 'BR',
                                    v: '0',
                                },
                                {
                                    j: 'BV',
                                    v: '1',
                                },
                                {
                                    j: 'B',
                                    v: '',
                                    customTitle: " "
                                },
                                {
                                    j: 'BZ',
                                    v: '3',
                                },
                                {
                                    j: 'CL',
                                    v: '1',
                                },
                                {
                                    j: 'CS',
                                    v: '0',
                                },
                                {
                                    j: 'CJ',
                                    v: '2',
                                },
                                {
                                    j: 'CT',
                                    v: '2',
                                },
                                {
                                    j: 'CV',
                                    v: '0',
                                },
                                {
                                    j: 'DB',
                                    v: '0',
                                },
                                {
                                    j: 'DJ',
                                    v: '2',
                                },
                                {
                                    j: 'GL',
                                    v: '0',
                                },
                                {
                                    j: 'GR',
                                    v: '1',
                                },
                                {
                                    j: 'GJ',
                                    v: '1',
                                },
                                {
                                    j: 'HR',
                                    v: '1',
                                },
                                {
                                    j: 'HD',
                                    v: '1',
                                },
                                {
                                    j: 'IL',
                                    v: '0',
                                },
                                {
                                    j: 'IS',
                                    v: '2',
                                },
                                {
                                    j: 'IF',
                                    v: '3',
                                },
                                {
                                    j: 'MM',
                                    v: '1',
                                },
                                {
                                    j: 'MH',
                                    v: '0',
                                },
                                {
                                    j: 'MS',
                                    v: '0',
                                },
                                {
                                    j: 'NT',
                                    v: '1',
                                },
                                {
                                    j: 'OT',
                                    v: '1',
                                },
                                {
                                    j: 'PH',
                                    v: '6',
                                },
                                {
                                    j: 'SJ',
                                    v: '1',
                                },
                                {
                                    j: 'SM',
                                    v: '0',
                                },
                                {
                                    j: 'SB',
                                    v: '0',
                                },
                                {
                                    j: 'SV',
                                    v: '2',
                                },
                                {
                                    j: 'TR',
                                    v: '1',
                                },
                                {
                                    j: 'TM',
                                    v: '1',
                                },
                                {
                                    j: 'TL',
                                    v: '0',
                                },
                                {
                                    j: 'VL',
                                    v: '2',
                                },
                                {
                                    j: 'VS',
                                    v: '0',
                                },
                                {
                                    j: 'VN',
                                    v: '2',
                                },
                            ],
                            //END: spitale rural
                        },
                        
                        {
                            name: "policlinici",
                            title: "Policlinici",

                            //START: policlinici urban
                            urban: [
                                {
                                    j: 'AB',
                                    v: '0',
                                },
                                {
                                    j: 'AR',
                                    v: '0',
                                },
                                {
                                    j: 'AG',
                                    v: '0',
                                },
                                {
                                    j: 'BC',
                                    v: '0',
                                },
                                {
                                    j: 'BH',
                                    v: '5',
                                },
                                {
                                    j: 'BN',
                                    v: '0',
                                },
                                {
                                    j: 'BT',
                                    v: '0',
                                },
                                {
                                    j: 'BR',
                                    v: '0',
                                },
                                {
                                    j: 'BV',
                                    v: '18',
                                },
                                {
                                    j: 'B',
                                    v: '9',
                                },
                                {
                                    j: 'BZ',
                                    v: '4',
                                },
                                {
                                    j: 'CL',
                                    v: '0',
                                },
                                {
                                    j: 'CS',
                                    v: '2',
                                },
                                {
                                    j: 'CJ',
                                    v: '7',
                                },
                                {
                                    j: 'CT',
                                    v: '1',
                                },
                                {
                                    j: 'CV',
                                    v: '0',
                                },
                                {
                                    j: 'DB',
                                    v: '0',
                                },
                                {
                                    j: 'DJ',
                                    v: '26',
                                },
                                {
                                    j: 'GL',
                                    v: '1',
                                },
                                {
                                    j: 'GR',
                                    v: '0',
                                },
                                {
                                    j: 'GJ',
                                    v: '0',
                                },
                                {
                                    j: 'HR',
                                    v: '0',
                                },
                                {
                                    j: 'HD',
                                    v: '0',
                                },
                                {
                                    j: 'IL',
                                    v: '0',
                                },
                                {
                                    j: 'IS',
                                    v: '0',
                                },
                                {
                                    j: 'IF',
                                    v: '0',
                                },
                                {
                                    j: 'MM',
                                    v: '0',
                                },
                                {
                                    j: 'MH',
                                    v: '0',
                                },
                                {
                                    j: 'MS',
                                    v: '0',
                                },
                                {
                                    j: 'NT',
                                    v: '0',
                                },
                                {
                                    j: 'OT',
                                    v: '0',
                                },
                                {
                                    j: 'PH',
                                    v: '0',
                                },
                                {
                                    j: 'SJ',
                                    v: '0',
                                },
                                {
                                    j: 'SM',
                                    v: '0',
                                },
                                {
                                    j: 'SB',
                                    v: '0',
                                },
                                {
                                    j: 'SV',
                                    v: '0',
                                },
                                {
                                    j: 'TR',
                                    v: '0',
                                },
                                {
                                    j: 'TM',
                                    v: '0',
                                },
                                {
                                    j: 'TL',
                                    v: '0',
                                },
                                {
                                    j: 'VL',
                                    v: '10',
                                },
                                {
                                    j: 'VS',
                                    v: '0',
                                },
                                {
                                    j: 'VN',
                                    v: '0',
                                },
                            ],
                            //END: policlinici urban

                            //START:policlinici rural
                            rural: [
                                {
                                    j: 'AB',
                                    v: '0',
                                },
                                {
                                    j: 'AR',
                                    v: '0',
                                },
                                {
                                    j: 'AG',
                                    v: '0',
                                },
                                {
                                    j: 'BC',
                                    v: '0',
                                },
                                {
                                    j: 'BH',
                                    v: '0',
                                },
                                {
                                    j: 'BN',
                                    v: '0',
                                },
                                {
                                    j: 'BT',
                                    v: '0',
                                },
                                {
                                    j: 'BR',
                                    v: '1',
                                },
                                {
                                    j: 'BV',
                                    v: '0',
                                },
                                {
                                    j: 'B',
                                    v: '',
                                    customTitle: " "
                                },
                                {
                                    j: 'BZ',
                                    v: '0',
                                },
                                {
                                    j: 'CL',
                                    v: '0',
                                },
                                {
                                    j: 'CS',
                                    v: '0',
                                },
                                {
                                    j: 'CJ',
                                    v: '0',
                                },
                                {
                                    j: 'CT',
                                    v: '0',
                                },
                                {
                                    j: 'CV',
                                    v: '0',
                                },
                                {
                                    j: 'DB',
                                    v: '0',
                                },
                                {
                                    j: 'DJ',
                                    v: '0',
                                },
                                {
                                    j: 'GL',
                                    v: '0',
                                },
                                {
                                    j: 'GR',
                                    v: '0',
                                },
                                {
                                    j: 'GJ',
                                    v: '0',
                                },
                                {
                                    j: 'HR',
                                    v: '0',
                                },
                                {
                                    j: 'HD',
                                    v: '0',
                                },
                                {
                                    j: 'IL',
                                    v: '0',
                                },
                                {
                                    j: 'IS',
                                    v: '0',
                                },
                                {
                                    j: 'IF',
                                    v: '1',
                                },
                                {
                                    j: 'MM',
                                    v: '0',
                                },
                                {
                                    j: 'MH',
                                    v: '0',
                                },
                                {
                                    j: 'MS',
                                    v: '0',
                                },
                                {
                                    j: 'NT',
                                    v: '0',
                                },
                                {
                                    j: 'OT',
                                    v: '0',
                                },
                                {
                                    j: 'PH',
                                    v: '0',
                                },
                                {
                                    j: 'SJ',
                                    v: '0',
                                },
                                {
                                    j: 'SM',
                                    v: '0',
                                },
                                {
                                    j: 'SB',
                                    v: '0',
                                },
                                {
                                    j: 'SV',
                                    v: '0',
                                },
                                {
                                    j: 'TR',
                                    v: '0',
                                },
                                {
                                    j: 'TM',
                                    v: '0',
                                },
                                {
                                    j: 'TL',
                                    v: '0',
                                },
                                {
                                    j: 'VL',
                                    v: '0',
                                },
                                {
                                    j: 'VS',
                                    v: '0',
                                },
                                {
                                    j: 'VN',
                                    v: '0',
                                },
                            ],
                            //END:policlinici rural
                        },

                        {
                            name: "cabinete_independente",
                            title: "Cabinete independente",

                            //START: cabinete independete urban
                            urban: [
                                {
                                    j: 'AB',
                                    v: 116,
                                },
                                {
                                    j: 'AR',
                                    v: 275,
                                },
                                {
                                    j: 'AG',
                                    v: 307,
                                },
                                {
                                    j: 'BC',
                                    v: 295,
                                },
                                {
                                    j: 'BH',
                                    v: 233,
                                },
                                {
                                    j: 'BN',
                                    v: 74,
                                },
                                {
                                    j: 'BT',
                                    v: 83,
                                },
                                {
                                    j: 'BR',
                                    v: 255,
                                },
                                {
                                    j: 'BV',
                                    v: 269,
                                },
                                {
                                    j: 'B',
                                    v: '2.072',
                                },
                                {
                                    j: 'BZ',
                                    v: 177,
                                },
                                {
                                    j: 'CL',
                                    v: 173,
                                },
                                {
                                    j: 'CS',
                                    v: 165,
                                },
                                {
                                    j: 'CJ',
                                    v: 348,
                                },
                                {
                                    j: 'CT',
                                    v: 502,
                                },
                                {
                                    j: 'CV',
                                    v: 82,
                                },
                                {
                                    j: 'DB',
                                    v: 126,
                                },
                                {
                                    j: 'DJ',
                                    v: 479,
                                },
                                {
                                    j: 'GL',
                                    v: 508,
                                },
                                {
                                    j: 'GR',
                                    v: 46,
                                },
                                {
                                    j: 'GJ',
                                    v: 171,
                                },
                                {
                                    j: 'HR',
                                    v: 192,
                                },
                                {
                                    j: 'HD',
                                    v: 193,
                                },
                                {
                                    j: 'IL',
                                    v: 85,
                                },
                                {
                                    j: 'IS',
                                    v: 453,
                                },
                                {
                                    j: 'IF',
                                    v: 232,
                                },
                                {
                                    j: 'MM',
                                    v: 252,
                                },
                                {
                                    j: 'MH',
                                    v: 167,
                                },
                                {
                                    j: 'MS',
                                    v: 173,
                                },
                                {
                                    j: 'NT',
                                    v: 430,
                                },
                                {
                                    j: 'OT',
                                    v: 84,
                                },
                                {
                                    j: 'PH',
                                    v: 356,
                                },
                                {
                                    j: 'SJ',
                                    v: 101,
                                },
                                {
                                    j: 'SM',
                                    v: 186,
                                },
                                {
                                    j: 'SB',
                                    v: 239,
                                },
                                {
                                    j: 'SV',
                                    v: 509,
                                },
                                {
                                    j: 'TR',
                                    v: 142,
                                },
                                {
                                    j: 'TM',
                                    v: 859,
                                },
                                {
                                    j: 'TL',
                                    v: 145,
                                },
                                {
                                    j: 'VL',
                                    v: 405,
                                },
                                {
                                    j: 'VS',
                                    v: 219,
                                },
                                {
                                    j: 'VN',
                                    v: 107,
                                },
                            ],
                            //END: cabinete independente urban

                            //START: cabinete independente rural
                            rural: [
                                {
                                    j: 'AB',
                                    v: '0',
                                },
                                {
                                    j: 'AR',
                                    v: 10,
                                },
                                {
                                    j: 'AG',
                                    v: 14,
                                },
                                {
                                    j: 'BC',
                                    v: 10,
                                },
                                {
                                    j: 'BH',
                                    v: 21,
                                },
                                {
                                    j: 'BN',
                                    v: 3,
                                },
                                {
                                    j: 'BT',
                                    v: 4,
                                },
                                {
                                    j: 'BR',
                                    v: '0',
                                },
                                {
                                    j: 'BV',
                                    v: 1,
                                },
                                {
                                    j: 'B',
                                    v: '',
                                    customTitle: " "
                                },
                                {
                                    j: 'BZ',
                                    v: 13,
                                },
                                {
                                    j: 'CL',
                                    v: 7,
                                },
                                {
                                    j: 'CS',
                                    v: 7,
                                },
                                {
                                    j: 'CJ',
                                    v: 14,
                                },
                                {
                                    j: 'CT',
                                    v: 4,
                                },
                                {
                                    j: 'CV',
                                    v: 2,
                                },
                                {
                                    j: 'DB',
                                    v: 12,
                                },
                                {
                                    j: 'DJ',
                                    v: 23,
                                },
                                {
                                    j: 'GL',
                                    v: 2,
                                },
                                {
                                    j: 'GR',
                                    v: 3,
                                },
                                {
                                    j: 'GJ',
                                    v: 3,
                                },
                                {
                                    j: 'HR',
                                    v: 15,
                                },
                                {
                                    j: 'HD',
                                    v: 2,
                                },
                                {
                                    j: 'IL',
                                    v: '0',
                                },
                                {
                                    j: 'IS',
                                    v: 17,
                                },
                                {
                                    j: 'IF',
                                    v: 167,
                                },
                                {
                                    j: 'MM',
                                    v: 1,
                                },
                                {
                                    j: 'MH',
                                    v: '0',
                                },
                                {
                                    j: 'MS',
                                    v: 3,
                                },
                                {
                                    j: 'NT',
                                    v: 25,
                                },
                                {
                                    j: 'OT',
                                    v: 7,
                                },
                                {
                                    j: 'PH',
                                    v: 20,
                                },
                                {
                                    j: 'SJ',
                                    v: 7,
                                },
                                {
                                    j: 'SM',
                                    v: 2,
                                },
                                {
                                    j: 'SB',
                                    v: 2,
                                },
                                {
                                    j: 'SV',
                                    v: 53,
                                },
                                {
                                    j: 'TR',
                                    v: 7,
                                },
                                {
                                    j: 'TM',
                                    v: 62,
                                },
                                {
                                    j: 'TL',
                                    v: 1,
                                },
                                {
                                    j: 'VL',
                                    v: 12,
                                },
                                {
                                    j: 'VS',
                                    v: 3,
                                },
                                {
                                    j: 'VN',
                                    v: '0',
                                },
                            ]
                            //END: cabinete independente rural
                        }
                    ]
                },
                //END: Număr unități sanitare

                //START: rata fertilitate(femei 15-19 ani)
                {
                    name: "rata_fertilitate_femei",
                    title: "Rată fertilitate (femei 15-19 ani)",

                    //START:rata fertilitate urban
                    urban: [
                        {
                            j: 'AB',
                            v: '21,5',
                        },
                        {
                            j: 'AR',
                            v: '25,3',
                        },
                        {
                            j: 'AG',
                            v: '12,1',
                        },
                        {
                            j: 'BC',
                            v: '18,1',
                        },
                        {
                            j: 'BH',
                            v: '19,0',
                        },
                        {
                            j: 'BN',
                            v: '15,9',
                        },
                        {
                            j: 'BT',
                            v: '10,7',
                        },
                        {
                            j: 'BR',
                            v: '15,3',
                        },
                        {
                            j: 'BV',
                            v: '24,0',
                        },
                        {
                            j: 'B',
                            v: '9,9',
                        },
                        {
                            j: 'BZ',
                            v: '20,6',
                        },
                        {
                            j: 'CL',
                            v: '41,9',
                        },
                        {
                            j: 'CS',
                            v: '23,7',
                        },
                        {
                            j: 'CJ',
                            v: '15,7',
                        },
                        {
                            j: 'CT',
                            v: '19,2',
                        },
                        {
                            j: 'CV',
                            v: '19,9',
                        },
                        {
                            j: 'DB',
                            v: '15,3',
                        },
                        {
                            j: 'DJ',
                            v: '28,6',
                        },
                        {
                            j: 'GL',
                            v: '16,9',
                        },
                        {
                            j: 'GR',
                            v: '35,7',
                        },
                        {
                            j: 'GJ',
                            v: '17,6',
                        },
                        {
                            j: 'HR',
                            v: '21,4',
                        },
                        {
                            j: 'HD',
                            v: '22,8',
                        },
                        {
                            j: 'IL',
                            v: '40,9',
                        },
                        {
                            j: 'IS',
                            v: '13,2',
                        },
                        {
                            j: 'IF',
                            v: '19,8',
                        },
                        {
                            j: 'MM',
                            v: '25,5',
                        },
                        {
                            j: 'MH',
                            v: '22,4',
                        },
                        {
                            j: 'MS',
                            v: '32,9',
                        },
                        {
                            j: 'NT',
                            v: '18,6',
                        },
                        {
                            j: 'OT',
                            v: '29,6',
                        },
                        {
                            j: 'PH',
                            v: '17,6',
                        },
                        {
                            j: 'SJ',
                            v: '28,5',
                        },
                        {
                            j: 'SM',
                            v: '20,8',
                        },
                        {
                            j: 'SB',
                            v: '20,0',
                        },
                        {
                            j: 'SV',
                            v: '12,6',
                        },
                        {
                            j: 'TR',
                            v: '29,1',
                        },
                        {
                            j: 'TM',
                            v: '16,7',
                        },
                        {
                            j: 'TL',
                            v: '27,4',
                        },
                        {
                            j: 'VL',
                            v: '19,9',
                        },
                        {
                            j: 'VS',
                            v: '18,3',
                        },
                        {
                            j: 'VN',
                            v: '20,8',
                        },
                    ],
                    //END:rata fertilitate urban

                    //START:rata fertilitate rural
                    rural: [
                        {
                            j: 'AB',
                            v: '31,3',
                        },
                        {
                            j: 'AR',
                            v: '30,2',
                        },
                        {
                            j: 'AG',
                            v: '46,3',
                        },
                        {
                            j: 'BC',
                            v: '33,3',
                        },
                        {
                            j: 'BH',
                            v: '49,3',
                        },
                        {
                            j: 'BN',
                            v: '31,8',
                        },
                        {
                            j: 'BT',
                            v: '24,8',
                        },
                        {
                            j: 'BR',
                            v: '39,8',
                        },
                        {
                            j: 'BV',
                            v: '80,7',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '44,7',
                        },
                        {
                            j: 'CL',
                            v: '53,1',
                        },
                        {
                            j: 'CS',
                            v: '32,6',
                        },
                        {
                            j: 'CJ',
                            v: '33,6',
                        },
                        {
                            j: 'CT',
                            v: '43,8',
                        },
                        {
                            j: 'CV',
                            v: '63,0',
                        },
                        {
                            j: 'DB',
                            v: '41,8',
                        },
                        {
                            j: 'DJ',
                            v: '53,0',
                        },
                        {
                            j: 'GL',
                            v: '37,2',
                        },
                        {
                            j: 'GR',
                            v: '39,2',
                        },
                        {
                            j: 'GJ',
                            v: '21,6',
                        },
                        {
                            j: 'HR',
                            v: '28,6',
                        },
                        {
                            j: 'HD',
                            v: '26,0',
                        },
                        {
                            j: 'IL',
                            v: '52,3',
                        },
                        {
                            j: 'IS',
                            v: '30,9',
                        },
                        {
                            j: 'IF',
                            v: '29,7',
                        },
                        {
                            j: 'MM',
                            v: '30,2',
                        },
                        {
                            j: 'MH',
                            v: '42,3',
                        },
                        {
                            j: 'MS',
                            v: '67,8',
                        },
                        {
                            j: 'NT',
                            v: '25,8',
                        },
                        {
                            j: 'OT',
                            v: '34,0',
                        },
                        {
                            j: 'PH',
                            v: '26,9',
                        },
                        {
                            j: 'SJ',
                            v: '56,2',
                        },
                        {
                            j: 'SM',
                            v: '45,1',
                        },
                        {
                            j: 'SB',
                            v: '66,3',
                        },
                        {
                            j: 'SV',
                            v: '26,4',
                        },
                        {
                            j: 'TR',
                            v: '44,4',
                        },
                        {
                            j: 'TM',
                            v: '27,3',
                        },
                        {
                            j: 'TL',
                            v: '29,0',
                        },
                        {
                            j: 'VL',
                            v: '32,4',
                        },
                        {
                            j: 'VS',
                            v: '35,6',
                        },
                        {
                            j: 'VN',
                            v: '39,9',
                        },
                    ]
                     //END:rata fertilitate rural
                },
                //END: rata fertilitate(femei 15-19 ani)

                //START: rata mortinatalitate

                {
                    name: "rata_mortalitate_2020",
                    title: "Rată mortinatalitate",

                    //START: rata mortinatalitate urban
                    urban: [
                        {
                            j: 'AB',
                            v: '2,5',
                        },
                        {
                            j: 'AR',
                            v: '5,3',
                        },
                        {
                            j: 'AG',
                            v: '1,8',
                        },
                        {
                            j: 'BC',
                            v: '2,1',
                        },
                        {
                            j: 'BH',
                            v: '5,2',
                        },
                        {
                            j: 'BN',
                            v: '5,2',
                        },
                        {
                            j: 'BT',
                            v: '4,4',
                        },
                        {
                            j: 'BR',
                            v: '2,5',
                        },
                        {
                            j: 'BV',
                            v: '2,5',
                        },
                        {
                            j: 'B',
                            v: '2,3',
                        },
                        {
                            j: 'BZ',
                            v: '6,6',
                        },
                        {
                            j: 'CL',
                            v: '-'
                        },
                        {
                            j: 'CS',
                            v: '6,4',
                        },
                        {
                            j: 'CJ',
                            v: '2,2',
                        },
                        {
                            j: 'CT',
                            v: '3,5',
                        },
                        {
                            j: 'CV',
                            v: '6,2',
                        },
                        {
                            j: 'DB',
                            v: '4,5',
                        },
                        {
                            j: 'DJ',
                            v: '0,7',
                        },
                        {
                            j: 'GL',
                            v: '2,7',
                        },
                        {
                            j: 'GR',
                            v: '1,6',
                        },
                        {
                            j: 'GJ',
                            v: '1,8',
                        },
                        {
                            j: 'HR',
                            v: '6,6',
                        },
                        {
                            j: 'HD',
                            v: '5,4',
                        },
                        {
                            j: 'IL',
                            v: '5,8',
                        },
                        {
                            j: 'IS',
                            v: '3,0',
                        },
                        {
                            j: 'IF',
                            v: '1,0',
                        },
                        {
                            j: 'MM',
                            v: '3,8',
                        },
                        {
                            j: 'MH',
                            v: '3,4',
                        },
                        {
                            j: 'MS',
                            v: '4,5',
                        },
                        {
                            j: 'NT',
                            v: '2,8',
                        },
                        {
                            j: 'OT',
                            v: '4,3',
                        },
                        {
                            j: 'PH',
                            v: '3,1',
                        },
                        {
                            j: 'SJ',
                            v: '10,9',
                        },
                        {
                            j: 'SM',
                            v: '4,7',
                        },
                        {
                            j: 'SB',
                            v: '1,3',
                        },
                        {
                            j: 'SV',
                            v: '4,1',
                        },
                        {
                            j: 'TR',
                            v: '6,5',
                        },
                        {
                            j: 'TM',
                            v: '3,4',
                        },
                        {
                            j: 'TL',
                            v: '2,5',
                        },
                        {
                            j: 'VL',
                            v: '5,4',
                        },
                        {
                            j: 'VS',
                            v: '2,7',
                        },
                        {
                            j: 'VN',
                            v: '5,4',
                        },
                    ],
                    //END: rata mortinatalitate urban

                    //START: rata mortinatalitate rural
                    rural: [
                        {
                            j: 'AB',
                            v: '4,4',
                        },
                        {
                            j: 'AR',
                            v: '5,2',
                        },
                        {
                            j: 'AG',
                            v: '4,5',
                        },
                        {
                            j: 'BC',
                            v: '5,3',
                        },
                        {
                            j: 'BH',
                            v: '8,7',
                        },
                        {
                            j: 'BN',
                            v: '7,3',
                        },
                        {
                            j: 'BT',
                            v: '4,6',
                        },
                        {
                            j: 'BR',
                            v: '-',
                        },
                        {
                            j: 'BV',
                            v: '2,2',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '5,4',
                        },
                        {
                            j: 'CL',
                            v: '6,2',
                        },
                        {
                            j: 'CS',
                            v: '-'
                        },
                        {
                            j: 'CJ',
                            v: '0,4',
                        },
                        {
                            j: 'CT',
                            v: '4,3',
                        },
                        {
                            j: 'CV',
                            v: '6,3',
                        },
                        {
                            j: 'DB',
                            v: '4,3',
                        },
                        {
                            j: 'DJ',
                            v: '0,8',
                        },
                        {
                            j: 'GL',
                            v: '4,4',
                        },
                        {
                            j: 'GR',
                            v: '2,7',
                        },
                        {
                            j: 'GJ',
                            v: '3,9',
                        },
                        {
                            j: 'HR',
                            v: '5,0',
                        },
                        {
                            j: 'HD',
                            v: '5,7',
                        },
                        {
                            j: 'IL',
                            v: '4,6',
                        },
                        {
                            j: 'IS',
                            v: '2,1',
                        },
                        {
                            j: 'IF',
                            v: '2,9',
                        },
                        {
                            j: 'MM',
                            v: '3,9',
                        },
                        {
                            j: 'MH',
                            v: '4,9',
                        },
                        {
                            j: 'MS',
                            v: '6,9',
                        },
                        {
                            j: 'NT',
                            v: '5,1',
                        },
                        {
                            j: 'OT',
                            v: '2,6',
                        },
                        {
                            j: 'PH',
                            v: '4,0',
                        },
                        {
                            j: 'SJ',
                            v: '5,6',
                        },
                        {
                            j: 'SM',
                            v: '7,7',
                        },
                        {
                            j: 'SB',
                            v: '2,5',
                        },
                        {
                            j: 'SV',
                            v: '7,5',
                        },
                        {
                            j: 'TR',
                            v: '5,8',
                        },
                        {
                            j: 'TM',
                            v: '3,2',
                        },
                        {
                            j: 'TL',
                            v: '9,9',
                        },
                        {
                            j: 'VL',
                            v: '2,5',
                        },
                        {
                            j: 'VS',
                            v: '2,9',
                        },
                        {
                            j: 'VN',
                            v: '2,7',
                        },
                    ]
                    //END: rata mortinatalitate rural
                },

                //START: rata mortalitatii infiantile
                {
                    name: "rata_mortalitatii_infantile_2020",
                    title: "Rată mortalitate infantilă",

                    //START: rata mortalitatii-infantile urban
                    urban: [
                        {
                            j: 'AB',
                            v: '5,6',
                        },
                        {
                            j: 'AR',
                            v: '5,3',
                        },
                        {
                            j: 'AG',
                            v: '2,7',
                        },
                        {
                            j: 'BC',
                            v: '5,6',
                        },
                        {
                            j: 'BH',
                            v: '7,6',
                        },
                        {
                            j: 'BN',
                            v: '4,3',
                        },
                        {
                            j: 'BT',
                            v: '3,7',
                        },
                        {
                            j: 'BR',
                            v: '4,1',
                        },
                        {
                            j: 'BV',
                            v: '6,2',
                        },
                        {
                            j: 'B',
                            v: '2,8',
                        },
                        {
                            j: 'BZ',
                            v: '1,7',
                        },
                        {
                            j: 'CL',
                            v: '11,7',
                        },
                        {
                            j: 'CS',
                            v: '6,4',
                        },
                        {
                            j: 'CJ',
                            v: '5,1',
                        },
                        {
                            j: 'CT',
                            v: '7,1',
                        },
                        {
                            j: 'CV',
                            v: '6,2',
                        },
                        {
                            j: 'DB',
                            v: '2,7',
                        },
                        {
                            j: 'DJ',
                            v: '5,4',
                        },
                        {
                            j: 'GL',
                            v: '4,9',
                        },
                        {
                            j: 'GR',
                            v: '6,4',
                        },
                        {
                            j: 'GJ',
                            v: '7,1',
                        },
                        {
                            j: 'HR',
                            v: '5,7',
                        },
                        {
                            j: 'HD',
                            v: '6,4',
                        },
                        {
                            j: 'IL',
                            v: '3,9',
                        },
                        {
                            j: 'IS',
                            v: '3,2',
                        },
                        {
                            j: 'IF',
                            v: '4,8',
                        },
                        {
                            j: 'MM',
                            v: '5,5',
                        },
                        {
                            j: 'MH',
                            v: '5,8',
                        },
                        {
                            j: 'MS',
                            v: '8,3',
                        },
                        {
                            j: 'NT',
                            v: '7,1',
                        },
                        {
                            j: 'OT',
                            v: '11,5',
                        },
                        {
                            j: 'PH',
                            v: '5,0',
                        },
                        {
                            j: 'SJ',
                            v: '5,0',
                        },
                        {
                            j: 'SM',
                            v: '15,1',
                        },
                        {
                            j: 'SB',
                            v: '4,4',
                        },
                        {
                            j: 'SV',
                            v: '7,5',
                        },
                        {
                            j: 'TR',
                            v: '2,6',
                        },
                        {
                            j: 'TM',
                            v: '4,5',
                        },
                        {
                            j: 'TL',
                            v: '16,6',
                        },
                        {
                            j: 'VL',
                            v: '5,4',
                        },
                        {
                            j: 'VS',
                            v: '1,6',
                        },
                        {
                            j: 'VN',
                            v: '1,1',
                        },
                    ],
                    //END: rata mortalitatii-infantile urban

                    //START: rata mortalitatii-infantile rural
                    rural: [
                        {
                            j: 'AB',
                            v: '8,0',
                        },
                        {
                            j: 'AR',
                            v: '7,5',
                        },
                        {
                            j: 'AG',
                            v: '7,9',
                        },
                        {
                            j: 'BC',
                            v: '6,3',
                        },
                        {
                            j: 'BH',
                            v: '6,8',
                        },
                        {
                            j: 'BN',
                            v: '9,4',
                        },
                        {
                            j: 'BT',
                            v: '8,9',
                        },
                        {
                            j: 'BR',
                            v: '11,1',
                        },
                        {
                            j: 'BV',
                            v: '10,2',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '5,0',
                        },
                        {
                            j: 'CL',
                            v: '5,0',
                        },
                        {
                            j: 'CS',
                            v: '2,4',
                        },
                        {
                            j: 'CJ',
                            v: '4,7',
                        },
                        {
                            j: 'CT',
                            v: '7,8',
                        },
                        {
                            j: 'CV',
                            v: '10,3',
                        },
                        {
                            j: 'DB',
                            v: '5,3',
                        },
                        {
                            j: 'DJ',
                            v: '8,7',
                        },
                        {
                            j: 'GL',
                            v: '9,4',
                        },
                        {
                            j: 'GR',
                            v: '4,0',
                        },
                        {
                            j: 'GJ',
                            v: '6,2',
                        },
                        {
                            j: 'HR',
                            v: '9,4',
                        },
                        {
                            j: 'HD',
                            v: '4,3',
                        },
                        {
                            j: 'IL',
                            v: '9,3',
                        },
                        {
                            j: 'IS',
                            v: '5,7',
                        },
                        {
                            j: 'IF',
                            v: '6,3',
                        },
                        {
                            j: 'MM',
                            v: '10,1',
                        },
                        {
                            j: 'MH',
                            v: '7,9',
                        },
                        {
                            j: 'MS',
                            v: '9,3',
                        },
                        {
                            j: 'NT',
                            v: '4,8',
                        },
                        {
                            j: 'OT',
                            v: '9,7',
                        },
                        {
                            j: 'PH',
                            v: '6,0',
                        },
                        {
                            j: 'SJ',
                            v: '7,0',
                        },
                        {
                            j: 'SM',
                            v: '11,1',
                        },
                        {
                            j: 'SB',
                            v: '11,1',
                        },
                        {
                            j: 'SV',
                            v: '10,2',
                        },
                        {
                            j: 'TR',
                            v: '6,5',
                        },
                        {
                            j: 'TM',
                            v: '4,8',
                        },
                        {
                            j: 'TL',
                            v: '5,0',
                        },
                        {
                            j: 'VL',
                            v: '5,8',
                        },
                        {
                            j: 'VS',
                            v: '5,8',
                        },
                        {
                            j: 'VN',
                            v: '6,0',
                        }, 
                    ]
                },
                //END: rata mortalitatii infantile

                //START: rata mortalitate generala
                {
                    name: "rata_mortalitate_generala_2020",
                    title: "Rată mortalitate generală",

                    //START: rata mortalitate generala urban
                    urban: [
                        {
                            j: 'AB',
                            v: '12,0',
                        },
                        {
                            j: 'AR',
                            v: '14,0',
                        },
                        {
                            j: 'AG',
                            v: '11,3',
                        },
                        {
                            j: 'BC',
                            v: '11,6',
                        },
                        {
                            j: 'BH',
                            v: '12,0',
                        },
                        {
                            j: 'BN',
                            v: '9,3',
                        },
                        {
                            j: 'BT',
                            v: '11,2',
                        },
                        {
                            j: 'BR',
                            v: '14,7',
                        },
                        {
                            j: 'BV',
                            v: '12,0',
                        },
                        {
                            j: 'B',
                            v: '12,0',
                        },
                        {
                            j: 'BZ',
                            v: '12,1',
                        },
                        {
                            j: 'CL',
                            v: '12,9',
                        },
                        {
                            j: 'CS',
                            v: '13,3',
                        },
                        {
                            j: 'CJ',
                            v: '11,1',
                        },
                        {
                            j: 'CT',
                            v: '12,7',
                        },
                        {
                            j: 'CV',
                            v: '11,8',
                        },
                        {
                            j: 'DB',
                            v: '11,7',
                        },
                        {
                            j: 'DJ',
                            v: '11,6',
                        },
                        {
                            j: 'GL',
                            v: '11,3',
                        },
                        {
                            j: 'GR',
                            v: '14,5',
                        },
                        {
                            j: 'GJ',
                            v: '10,4',
                        },
                        {
                            j: 'HR',
                            v: '11,6',
                        },
                        {
                            j: 'HD',
                            v: '14,7',
                        },
                        {
                            j: 'IL',
                            v: '11,9',
                        },
                        {
                            j: 'IS',
                            v: '8,9',
                        },
                        {
                            j: 'IF',
                            v: '9,5',
                        },
                        {
                            j: 'MM',
                            v: '12,1',
                        },
                        {
                            j: 'MH',
                            v: '10,8',
                        },
                        {
                            j: 'MS',
                            v: '13,1',
                        },
                        {
                            j: 'NT',
                            v: '12,3',
                        },
                        {
                            j: 'OT',
                            v: '11,2',
                        },
                        {
                            j: 'PH',
                            v: '14,9',
                        },
                        {
                            j: 'SJ',
                            v: '9,1',
                        },
                        {
                            j: 'SM',
                            v: '12,6',
                        },
                        {
                            j: 'SB',
                            v: '12,0',
                        },
                        {
                            j: 'SV',
                            v: '11,0',
                        },
                        {
                            j: 'TR',
                            v: '12,5',
                        },
                        {
                            j: 'TM',
                            v: '11,5',
                        },
                        {
                            j: 'TL',
                            v: '12,3',
                        },
                        {
                            j: 'VL',
                            v: '10,4',
                        },
                        {
                            j: 'VS',
                            v: '8,3',
                        },
                        {
                            j: 'VN',
                            v: '10,3',
                        },
                    ],
                    //END: rata moratalitate generala urban

                    //START: rata mortalitate generala rural
                    rural: [
                        {
                            j: 'AB',
                            v: '15,8',
                        },
                        {
                            j: 'AR',
                            v: '15,6',
                        },
                        {
                            j: 'AG',
                            v: '17,5',
                        },
                        {
                            j: 'BC',
                            v: '15,0',
                        },
                        {
                            j: 'BH',
                            v: '15,7',
                        },
                        {
                            j: 'BN',
                            v: '14,4',
                        },
                        {
                            j: 'BT',
                            v: '16,7',
                        },
                        {
                            j: 'BR',
                            v: '18,8',
                        },
                        {
                            j: 'BV',
                            v: '12,0',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '18,8',
                        },
                        {
                            j: 'CL',
                            v: '18,3',
                        },
                        {
                            j: 'CS',
                            v: '17,0',
                        },
                        {
                            j: 'CJ',
                            v: '15,1',
                        },
                        {
                            j: 'CT',
                            v: '12,4',
                        },
                        {
                            j: 'CV',
                            v: '13,7',
                        },
                        {
                            j: 'DB',
                            v: '16,2',
                        },
                        {
                            j: 'DJ',
                            v: '18,7',
                        },
                        {
                            j: 'GL',
                            v: '14,3',
                        },
                        {
                            j: 'GR',
                            v: '19,1',
                        },
                        {
                            j: 'GJ',
                            v: '15,7',
                        },
                        {
                            j: 'HR',
                            v: '13,8',
                        },
                        {
                            j: 'HD',
                            v: '19,0',
                        },
                        {
                            j: 'IL',
                            v: '18,9',
                        },
                        {
                            j: 'IS',
                            v: '11,7',
                        },
                        {
                            j: 'IF',
                            v: '11,4',
                        },
                        {
                            j: 'MM',
                            v: '14,3',
                        },
                        {
                            j: 'MH',
                            v: '18,8',
                        },
                        {
                            j: 'MS',
                            v: '15,1',
                        },
                        {
                            j: 'NT',
                            v: '15,5',
                        },
                        {
                            j: 'OT',
                            v: '20,7',
                        },
                        {
                            j: 'PH',
                            v: '17,0',
                        },
                        {
                            j: 'SJ',
                            v: '17,8',
                        },
                        {
                            j: 'SM',
                            v: '13,4',
                        },
                        {
                            j: 'SB',
                            v: '12,3',
                        },
                        {
                            j: 'SV',
                            v: '13,5',
                        },
                        {
                            j: 'TR',
                            v: '21,7',
                        },
                        {
                            j: 'TM',
                            v: '11,1',
                        },
                        {
                            j: 'TL',
                            v: '17,0',
                        },
                        {
                            j: 'VL',
                            v: '11,0',
                        },
                        {
                            j: 'VS',
                            v: '15,4',
                        },
                        {
                            j: 'VN',
                            v: '15,6',
                        }, 
                    ]
                    //END: rata moratalitate generala rural
                },
                //END: rata mortalitate generala

                //START: numar intreruperi sarcina 2019
                {
                    name: "numar_intreruperi_sarcina_2019",
                    title: "Rata întreruperi sarcină",

                    //START:rata intreruperi sarcina urban
                    urban: [
                        {
                            j: 'AB',
                            v: '3,5',
                        },
                        {
                            j: 'AR',
                            v: '3,2',
                        },
                        {
                            j: 'AG',
                            v: '4,1',
                        },
                        {
                            j: 'BC',
                            v: '11,9',
                        },
                        {
                            j: 'BH',
                            v: '7,7',
                        },
                        {
                            j: 'BN',
                            v: '16,2',
                        },
                        {
                            j: 'BT',
                            v: '3,9',
                        },
                        {
                            j: 'BR',
                            v: '13,3',
                        },
                        {
                            j: 'BV',
                            v: '9,5',
                        },
                        {
                            j: 'B',
                            v: '7,5',
                        },
                        {
                            j: 'BZ',
                            v: '12,0',
                        },
                        {
                            j: 'CL',
                            v: '6,0',
                        },
                        {
                            j: 'CS',
                            v: '10,0',
                        },
                        {
                            j: 'CJ',
                            v: '7,3',
                        },
                        {
                            j: 'CT',
                            v: '12,7',
                        },
                        {
                            j: 'CV',
                            v: '20,6',
                        },
                        {
                            j: 'DB',
                            v: '3,7',
                        },
                        {
                            j: 'DJ',
                            v: '5,8',
                        },
                        {
                            j: 'GL',
                            v: '3,2',
                        },
                        {
                            j: 'GR',
                            v: '4,3',
                        },
                        {
                            j: 'GJ',
                            v: '11,7',
                        },
                        {
                            j: 'HR',
                            v: '9,6',
                        },
                        {
                            j: 'HD',
                            v: '4,1',
                        },
                        {
                            j: 'IL',
                            v: '16,7',
                        },
                        {
                            j: 'IS',
                            v: '3,6',
                        },
                        {
                            j: 'IF',
                            v: '2,8',
                        },
                        {
                            j: 'MM',
                            v: '5,1',
                        },
                        {
                            j: 'MH',
                            v: '7,8',
                        },
                        {
                            j: 'MS',
                            v: '6,3',
                        },
                        {
                            j: 'NT',
                            v: '7,1',
                        },
                        {
                            j: 'OT',
                            v: '3,2',
                        },
                        {
                            j: 'PH',
                            v: '3,7',
                        },
                        {
                            j: 'SJ',
                            v: '3,8',
                        },
                        {
                            j: 'SM',
                            v: '5,7',
                        },
                        {
                            j: 'SB',
                            v: '6,0',
                        },
                        {
                            j: 'SV',
                            v: '4,0',
                        },
                        {
                            j: 'TR',
                            v: '23,2',
                        },
                        {
                            j: 'TM',
                            v: '3,7',
                        },
                        {
                            j: 'TL',
                            v: '10,7',
                        },
                        {
                            j: 'VL',
                            v: '3,9',
                        },
                        {
                            j: 'VS',
                            v: '6,1',
                        },
                        {
                            j: 'VN',
                            v: '5,2',
                        },
                    ],
                    //END: rata intreruperi sarcina urban

                    //START: rata intreruperi sarcina rural
                    rural: [
                        {
                            j: 'AB',
                            v: '5,9',
                        },
                        {
                            j: 'AR',
                            v: '3,8',
                        },
                        {
                            j: 'AG',
                            v: '6,7',
                        },
                        {
                            j: 'BC',
                            v: '9,3',
                        },
                        {
                            j: 'BH',
                            v: '12,0',
                        },
                        {
                            j: 'BN',
                            v: '8,6',
                        },
                        {
                            j: 'BT',
                            v: '6,1',
                        },
                        {
                            j: 'BR',
                            v: '10,6',
                        },
                        {
                            j: 'BV',
                            v: '6,8',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '13,4',
                        },
                        {
                            j: 'CL',
                            v: '2,5',
                        },
                        {
                            j: 'CS',
                            v: '6,9',
                        },
                        {
                            j: 'CJ',
                            v: '11,0',
                        },
                        {
                            j: 'CT',
                            v: '5,9',
                        },
                        {
                            j: 'CV',
                            v: '24,8',
                        },
                        {
                            j: 'DB',
                            v: '3,6',
                        },
                        {
                            j: 'DJ',
                            v: '8,3',
                        },
                        {
                            j: 'GL',
                            v: '4,7',
                        },
                        {
                            j: 'GR',
                            v: '3,2',
                        },
                        {
                            j: 'GJ',
                            v: '14,7',
                        },
                        {
                            j: 'HR',
                            v: '10,7',
                        },
                        {
                            j: 'HD',
                            v: '3,3',
                        },
                        {
                            j: 'IL',
                            v: '21,8',
                        },
                        {
                            j: 'IS',
                            v: '3,6',
                        },
                        {
                            j: 'IF',
                            v: '4,6',
                        },
                        {
                            j: 'MM',
                            v: '5,8',
                        },
                        {
                            j: 'MH',
                            v: '7,2',
                        },
                        {
                            j: 'MS',
                            v: '8,1',
                        },
                        {
                            j: 'NT',
                            v: '8,3',
                        },
                        {
                            j: 'OT',
                            v: '3,2',
                        },
                        {
                            j: 'PH',
                            v: '3,8',
                        },
                        {
                            j: 'SJ',
                            v: '3,3',
                        },
                        {
                            j: 'SM',
                            v: '4,9',
                        },
                        {
                            j: 'SB',
                            v: '9,8',
                        },
                        {
                            j: 'SV',
                            v: '5,8',
                        },
                        {
                            j: 'TR',
                            v: '14,2',
                        },
                        {
                            j: 'TM',
                            v: '5,4',
                        },
                        {
                            j: 'TL',
                            v: '7,2',
                        },
                        {
                            j: 'VL',
                            v: '6,8',
                        },
                        {
                            j: 'VS',
                            v: '8,6',
                        },
                        {
                            j: 'VN',
                            v: '4,7',
                        },
                    ]
                    //END: rata intreruperi sarcina tutal
                },
                //END: numar intreruperi sarcina 2019

                //START: rata vaccinare covid-19 februarie 2022
                {
                    name: "rata_vaccinare_covid19",
                    title: "Rată vaccinare COVID-19",

                    //START: rata vaccinare urban
                    urban: [
                        {
                            j: 'AB',
                            v: '40,91',
                        },
                        {
                            j: 'AR',
                            v: '36,40',
                        },
                        {
                            j: 'AG',
                            v: '44,02',
                        },
                        {
                            j: 'BC',
                            v: '30,12',
                        },
                        {
                            j: 'BH',
                            v: '40,36',
                        },
                        {
                            j: 'BN',
                            v: '42,29',
                        },
                        {
                            j: 'BT',
                            v: '33,79',
                        },
                        {
                            j: 'BR',
                            v: '36,66',
                        },
                        {
                            j: 'BV',
                            v: '40,28',
                        },
                        {
                            j: 'B',
                            v: '49,59',
                        },
                        {
                            j: 'BZ',
                            v: '39,20',
                        },
                        {
                            j: 'CL',
                            v: '41,21',
                        },
                        {
                            j: 'CS',
                            v: '37,05',
                        },
                        {
                            j: 'CJ',
                            v: '51,48',
                        },
                        {
                            j: 'CT',
                            v: '47,01',
                        },
                        {
                            j: 'CV',
                            v: '31,25',
                        },
                        {
                            j: 'DB',
                            v: '43,68',
                        },
                        {
                            j: 'DJ',
                            v: '46,17',
                        },
                        {
                            j: 'GL',
                            v: '39,11',
                        },
                        {
                            j: 'GR',
                            v: '37,68',
                        },
                        {
                            j: 'GJ',
                            v: '40,59',
                        },
                        {
                            j: 'HR',
                            v: '37,06',
                        },
                        {
                            j: 'HD',
                            v: '36,54',
                        },
                        {
                            j: 'IL',
                            v: '40,48',
                        },
                        {
                            j: 'IS',
                            v: '35,98',
                        },
                        {
                            j: 'IF',
                            v: '47,66',
                        },
                        {
                            j: 'MM',
                            v: '36,93',
                        },
                        {
                            j: 'MH',
                            v: '37,97',
                        },
                        {
                            j: 'MS',
                            v: '42,18',
                        },
                        {
                            j: 'NT',
                            v: '35,03',
                        },
                        {
                            j: 'OT',
                            v: '41,70',
                        },
                        {
                            j: 'PH',
                            v: '44,32',
                        },
                        {
                            j: 'SJ',
                            v: '43,48',
                        },
                        {
                            j: 'SM',
                            v: '41,05',
                        },
                        {
                            j: 'SB',
                            v: '43,95',
                        },
                        {
                            j: 'SV',
                            v: '27,58',
                        },
                        {
                            j: 'TR',
                            v: '40,99',
                        },
                        {
                            j: 'TM',
                            v: '42,53',
                        },
                        {
                            j: 'TL',
                            v: '41,66',
                        },
                        {
                            j: 'VL',
                            v: '43,85',
                        },
                        {
                            j: 'VS',
                            v: '28,40',
                        },
                        {
                            j: 'VN',
                            v: '34,56',
                        },
                    ],
                    //END: rata vaccinare urban

                    //START: rata vaccinare rural
                    rural: [
                        {
                            j: 'AB',
                            v: '28,44',
                        },
                        {
                            j: 'AR',
                            v: '25,01',
                        },
                        {
                            j: 'AG',
                            v: '29,70',
                        },
                        {
                            j: 'BC',
                            v: '17,15',
                        },
                        {
                            j: 'BH',
                            v: '24,92',
                        },
                        {
                            j: 'BN',
                            v: '25,43',
                        },
                        {
                            j: 'BT',
                            v: '18,85',
                        },
                        {
                            j: 'BR',
                            v: '21,39',
                        },
                        {
                            j: 'BV',
                            v: '26,07',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '25,90',
                        },
                        {
                            j: 'CL',
                            v: '28,72',
                        },
                        {
                            j: 'CS',
                            v: '27,06',
                        },
                        {
                            j: 'CJ',
                            v: '40,24',
                        },
                        {
                            j: 'CT',
                            v: '31,83',
                        },
                        {
                            j: 'CV',
                            v: '15,55',
                        },
                        {
                            j: 'DB',
                            v: '31,83',
                        },
                        {
                            j: 'DJ',
                            v: '30,13',
                        },
                        {
                            j: 'GL',
                            v: '21,43',
                        },
                        {
                            j: 'GR',
                            v: '25,18',
                        },
                        {
                            j: 'GJ',
                            v: '29,96',
                        },
                        {
                            j: 'HR',
                            v: '21,66',
                        },
                        {
                            j: 'HD',
                            v: '27,40',
                        },
                        {
                            j: 'IL',
                            v: '27,46',
                        },
                        {
                            j: 'IS',
                            v: '23,76',
                        },
                        {
                            j: 'IF',
                            v: '41,96',
                        },
                        {
                            j: 'MM',
                            v: '23,77',
                        },
                        {
                            j: 'MH',
                            v: '22,09',
                        },
                        {
                            j: 'MS',
                            v: '30,12',
                        },
                        {
                            j: 'NT',
                            v: '20,83',
                        },
                        {
                            j: 'OT',
                            v: '29,35',
                        },
                        {
                            j: 'PH',
                            v: '32,24',
                        },
                        {
                            j: 'SJ',
                            v: '29,00',
                        },
                        {
                            j: 'SM',
                            v: '26,35',
                        },
                        {
                            j: 'SB',
                            v: '29,13',
                        },
                        {
                            j: 'SV',
                            v: '15,40',
                        },
                        {
                            j: 'TR',
                            v: '28,14',
                        },
                        {
                            j: 'TM',
                            v: '33,58',
                        },
                        {
                            j: 'TL',
                            v: '30,10',
                        },
                        {
                            j: 'VL',
                            v: '31,65',
                        },
                        {
                            j: 'VS',
                            v: '20,51',
                        },
                        {
                            j: 'VN',
                            v: '20,00',
                        }, 
                    ]
                    //END: rata vaccinare rural
                }
                //END: rata de vaccinare covid-19 februarie 2022 
            ]
        },
        //END:Sanatate

        //START:Educatie
        {
            name: "educatie",
            title: "Educație",
            subcategories: [
                //START: rata absolvire invatamant
                {
                    name: "rata_absolvire_invatamant_%",
                    title: "Rată de absolvire învățământ primar/gimnazial/liceal",
                    subcategories: [
                        {
                            name: "primar",
                            title: "Rată de absolvire a învățământului primar",

                            //START:primar urban
                            urban: [
                                {
                                    j: 'AB',
                                    v: '94,70',
                                    a: 2020,
                                },
                                {
                                    j: 'AR',
                                    v: '91,78',
                                    a: 2021,
                                },
                                {
                                    j: 'AG',
                                    v: '99,46',
                                    a: 2020,
                                },
                                {
                                    j: 'BC',
                                    v: '95,33',
                                    a: 2021,
                                },
                                {
                                    j: 'BH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BN',
                                    v: '97,80',
                                    a: 2020,
                                },
                                {
                                    j: 'BT',
                                    v: '99,15',
                                    a: 2020,
                                },
                                {
                                    j: 'BR',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BV',
                                    v: '90,16',
                                    a: 2020,
                                },
                                {
                                    j: 'B',
                                    v: '96,97',
                                    a: 2021,
                                    customTitle: ' ',
                                },
                                {
                                    j: 'BZ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'CL',
                                    v: '94,90',
                                    a: 2021,
                                },
                                {
                                    j: 'CS',
                                    v: '90,25',
                                },
                                {
                                    j: 'CJ',
                                    v: '96,45',
                                    a: 2021,
                                },
                                {
                                    j: 'CT',
                                    v: '95,63',
                                    a: 2021,
                                },
                                {
                                    j: 'CV',
                                    v: '92,72',
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'DB',
                                    v: '97,42',
                                    a: 2021,
                                },
                                {
                                    j: 'DJ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'GL',
                                    v: '96,47',
                                    a: 2020,
                                },
                                {
                                    j: 'GR',
                                    v: '97,68',
                                    a: 2021,
                                },
                                {
                                    j: 'GJ',
                                    v: '99,86',
                                },
                                {
                                    j: 'HR',
                                    v: '96,32',
                                },
                                {
                                    j: 'HD',
                                    v: '98,26',
                                    a: 2020,
                                },
                                {
                                    j: 'IL',
                                    v: '96,30',
                                    a: 2021,
                                },
                                {
                                    j: 'IS',
                                    v: '98,57',
                                    a: 2021,
                                },
                                {
                                    j: 'IF',
                                    v: '98,36',
                                    a: 2020,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'MM',
                                    v: '96,87',
                                    a: 2021,
                                },
                                {
                                    j: 'MH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'MS',
                                    v: '97,55',
                                    a: 2020,
                                },
                                {
                                    j: 'NT',
                                    v: '94,99',
                                    a: 2021,
                                },
                                {
                                    j: 'OT',
                                    v: '98,34',
                                    a: 2020,
                                },
                                {
                                    j: 'PH',
                                    v: '97,40',
                                    a: 2021,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'SJ',
                                    v: '99,05',
                                    a: 2021,
                                },
                                {
                                    j: 'SM',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'SB',
                                    v: '98,63',
                                    a: 2021,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'SV',
                                    v: '97,15',
                                    a: 2020,
                                },
                                {
                                    j: 'TR',
                                    v: '99,98',
                                    a: 2020,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'TM',
                                    v: '99,18',
                                    a: 2021,
                                },
                                {
                                    j: 'TL',
                                    v: '96,38',
                                    a: 2020,
                                },
                                {
                                    j: 'VL',
                                    v: '98,25',
                                    a: 2021,
                                },
                                {
                                    j: 'VS',
                                    v: '97,66',
                                    a: 2021,
                                },
                                {
                                    j: 'VN',
                                    v: '97,43',
                                    a: 2021,
                                },
                            ],
                            //END: primar urban

                            //START: primar rural
                            rural: [
                                {
                                    j: 'AB',
                                    v: '75,89',
                                    a: 2020,
                                },
                                {
                                    j: 'AR',
                                    v: '90,31',
                                    a: 2021,
                                },
                                {
                                    j: 'AG',
                                    v: '94,52',
                                    a: 2020,
                                },
                                {
                                    j: 'BC',
                                    v: '93,08',
                                    a: 2021,
                                },
                                {
                                    j: 'BH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BN',
                                    v: '95,30',
                                    a: 2020,
                                },
                                {
                                    j: 'BT',
                                    v: '99,25',
                                    a: 2020,
                                },
                                {
                                    j: 'BR',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BV',
                                    v: '88,31',
                                    a: 2020,
                                },
                                {
                                    j: 'B',
                                    v: '',
                                    customTitle: " "
                                },
                                {
                                    j: 'BZ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'CL',
                                    v: '93,66',
                                    a: 2021,
                                },
                                {
                                    j: 'CS',
                                    v: '94,29',
                                },
                                {
                                    j: 'CJ',
                                    v: '91,82',
                                    a: 2021,
                                },
                                {
                                    j: 'CT',
                                    v: '95,56',
                                    a: 2021,
                                },
                                {
                                    j: 'CV',
                                    v: '92,72',
                                },
                                {
                                    j: 'DB',
                                    v: '95,05',
                                    a: 2021,
                                },
                                {
                                    j: 'DJ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'GL',
                                    v: '91,26',
                                    a: 2020,
                                },
                                {
                                    j: 'GR',
                                    v: '96,20',
                                    a: 2021,
                                },
                                {
                                    j: 'GJ',
                                    v: '99,51',
                                },
                                {
                                    j: 'HR',
                                    v: '93,85',
                                },
                                {
                                    j: 'HD',
                                    v: '97,22',
                                    a: 2020,
                                },
                                {
                                    j: 'IL',
                                    v: '95,65',
                                    a: 2021,
                                },
                                {
                                    j: 'IS',
                                    v: '95,04',
                                    a: 2021,
                                },
                                {
                                    j: 'IF',
                                    v: '98,36',
                                    a: 2020,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'MM',
                                    v: '98,60',
                                    a: 2021,
                                },
                                {
                                    j: 'MH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'MS',
                                    v: '91,43',
                                    a: 2020,
                                },
                                {
                                    j: 'NT',
                                    v: '95,12',
                                    a: 2021,
                                },
                                {
                                    j: 'OT',
                                    v: '95,24',
                                    a: 2020,
                                },
                                {
                                    j: 'PH',
                                    v: '97,40',
                                    a: 2021,
                                },
                                {
                                    j: 'SJ',
                                    v: '95,58',
                                    a: 2021,
                                },
                                {
                                    j: 'SM',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'SB',
                                    v: '98,63',
                                    a: 2021,
                                },
                                {
                                    j: 'SV',
                                    v: '95,97',
                                    a: 2020,
                                },
                                {
                                    j: 'TR',
                                    v: '99,98',
                                    a: 2020,
                                },
                                {
                                    j: 'TM',
                                    v: '97,64',
                                    a: 2021,
                                },
                                {
                                    j: 'TL',
                                    v: '99,24',
                                    a: 2020,
                                },
                                {
                                    j: 'VL',
                                    v: '98,87',
                                    a: 2021,
                                },
                                {
                                    j: 'VS',
                                    v: '96,34',
                                    a: 2021,
                                },
                                {
                                    j: 'VN',
                                    v: '94,68',
                                    a: 2021,
                                },
                            ]
                            //END: primar rual
                        },

                        {
                            name: "gimnazial",
                            title: "Rată de absolvire a învățământului gimnazial",

                            //START: gimnazial urban
                            urban: [
                                {
                                    j: 'AB',
                                    v: '90,00',
                                    a: 2020,
                                },
                                {
                                    j: 'AR',
                                    v: '90,47',
                                    a: 2021,
                                },
                                {
                                    j: 'AG',
                                    v: '99,14',
                                    a: 2020,
                                },
                                {
                                    j: 'BC',
                                    v: '93,87',
                                    a: 2021,
                                },
                                {
                                    j: 'BH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BN',
                                    v: '93,42',
                                    a: 2020,
                                },
                                {
                                    j: 'BT',
                                    v: '98,11',
                                    a: 2020,
                                },
                                {
                                    j: 'BR',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BV',
                                    v: '86,99',
                                    a: 2020,
                                },
                                {
                                    j: 'B',
                                    v: '96,97',
                                    a: 2021,
                                    customTitle: ' ',
                                },
                                {
                                    j: 'BZ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'CL',
                                    v: '88,40',
                                    a: 2021,
                                },
                                {
                                    j: 'CS',
                                    v: '85,81',
                                },
                                {
                                    j: 'CJ',
                                    v: '94,27',
                                    a: 2021,
                                },
                                {
                                    j: 'CT',
                                    v: '92,63',
                                    a: 2021,
                                },
                                {
                                    j: 'CV',
                                    v: '93,97',
                                },
                                {
                                    j: 'DB',
                                    v: '96,13',
                                    a: 2021,
                                },
                                {
                                    j: 'DJ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'GL',
                                    v: '91,61',
                                    a: 2020,
                                },
                                {
                                    j: 'GR',
                                    v: '95,42',
                                    a: 2021,
                                },
                                {
                                    j: 'GJ',
                                    v: '98,27',
                                },
                                {
                                    j: 'HR',
                                    v: '96,53',
                                },
                                {
                                    j: 'HD',
                                    v: '94,43',
                                    a: 2020,
                                },
                                {
                                    j: 'IL',
                                    v: '92,24',
                                    a: 2021,
                                },
                                {
                                    j: 'IS',
                                    v: '97,62',
                                    a: 2021,
                                },
                                {
                                    j: 'IF',
                                    v: '96,80',
                                    a: 2020,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'MM',
                                    v: '93,90',
                                    a: 2021,
                                },
                                {
                                    j: 'MH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'MS',
                                    v: '96,79',
                                    a: 2020,
                                },
                                {
                                    j: 'NT',
                                    v: '94,69',
                                    a: 2021,
                                },
                                {
                                    j: 'OT',
                                    v: '91,62',
                                    a: 2020,
                                },
                                {
                                    j: 'PH',
                                    v: '91,28',
                                    a: 2021,
                                },
                                {
                                    j: 'SJ',
                                    v: '97,50',
                                    a: 2021,
                                },
                                {
                                    j: 'SM',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'SB',
                                    v: '88,35',
                                    a: 2021,
                                },
                                {
                                    j: 'SV',
                                    v: '98,79',
                                    a: 2020,
                                },
                                {
                                    j: 'TR',
                                    v: '99,96',
                                    a: 2020,
                                },
                                {
                                    j: 'TM',
                                    v: '97,92',
                                    a: 2021,
                                },
                                {
                                    j: 'TL',
                                    v: '97,06',
                                    a: 2020,
                                },
                                {
                                    j: 'VL',
                                    v: '94,98',
                                    a: 2021,
                                },
                                {
                                    j: 'VS',
                                    v: '91,86',
                                    a: 2021,
                                },
                                {
                                    j: 'VN',
                                    v: '94,98',
                                    a: 2021,
                                },
                            ],
                            //END: gimnazial urban

                            //START:gimnazial rural
                            rural: [
                                {
                                    j: 'AB',
                                    v: '77,09',
                                    a: 2020,
                                },
                                {
                                    j: 'AR',
                                    v: '85,98',
                                    a: 2021,
                                },
                                {
                                    j: 'AG',
                                    v: '91,62',
                                    a: 2020,
                                },
                                {
                                    j: 'BC',
                                    v: '90,85',
                                    a: 2021,
                                },
                                {
                                    j: 'BH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BN',
                                    v: '82,41',
                                    a: 2020,
                                },
                                {
                                    j: 'BT',
                                    v: '97,31',
                                    a: 2020,
                                },
                                {
                                    j: 'BR',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BV',
                                    v: '78,64',
                                    a: 2020,
                                },
                                {
                                    j: 'B',
                                    v: '',
                                    customTitle: " "
                                },
                                {
                                    j: 'BZ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'CL',
                                    v: '85,46',
                                    a: 2021,
                                },
                                {
                                    j: 'CS',
                                    v: '87,14',
                                },
                                {
                                    j: 'CJ',
                                    v: '89,40',
                                    a: 2021,
                                },
                                {
                                    j: 'CT',
                                    v: '82,88',
                                    a: 2021,
                                },
                                {
                                    j: 'CV',
                                    v: '93,97',
                                },
                                {
                                    j: 'DB',
                                    v: '92,19',
                                    a: 2021,
                                },
                                {
                                    j: 'DJ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'GL',
                                    v: '90,05',
                                    a: 2020,
                                },
                                {
                                    j: 'GR',
                                    v: '94,37',
                                    a: 2021,
                                },
                                {
                                    j: 'GJ',
                                    v: '99,06',
                                },
                                {
                                    j: 'HR',
                                    v: '91,94',
                                },
                                {
                                    j: 'HD',
                                    v: '88,72',
                                    a: 2020,
                                },
                                {
                                    j: 'IL',
                                    v: '88,82',
                                    a: 2021,
                                },
                                {
                                    j: 'IS',
                                    v: '93,81',
                                    a: 2021,
                                },
                                {
                                    j: 'IF',
                                    v: '96,80',
                                    a: 2020,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'MM',
                                    v: '94,39',
                                    a: 2021,
                                },
                                {
                                    j: 'MH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'MS',
                                    v: '87,67',
                                    a: 2020,
                                },
                                {
                                    j: 'NT',
                                    v: '94,29',
                                    a: 2021,
                                },
                                {
                                    j: 'OT',
                                    v: '91,95',
                                    a: 2020,
                                },
                                {
                                    j: 'PH',
                                    v: '92,28',
                                    a: 2021,
                                },
                                {
                                    j: 'SJ',
                                    v: '93,26',
                                    a: 2021,
                                },
                                {
                                    j: 'SM',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'SB',
                                    v: '88,35',
                                    a: 2021,
                                },
                                {
                                    j: 'SV',
                                    v: '97,22',
                                    a: 2020,
                                },
                                {
                                    j: 'TR',
                                    v: '99,96',
                                    a: 2020,
                                },
                                {
                                    j: 'TM',
                                    v: '94,73',
                                    a: 2021,
                                },
                                {
                                    j: 'TL',
                                    v: '96,04',
                                    a: 2020,
                                },
                                {
                                    j: 'VL',
                                    v: '93,17',
                                    a: 2021,
                                },
                                {
                                    j: 'VS',
                                    v: '90,52',
                                    a: 2021,
                                },
                                {
                                    j: 'VN',
                                    v: '93,17',
                                    a: 2021,
                                },
                            ]
                            //END: gimnazial rural
                        },

                        {
                            name: "liceal",
                            title: "Rată de absolvire a învățământului liceal",

                            //START: liceal urban
                            urban: [
                                {
                                    j: 'AB',
                                    v: '92,49',
                                    a: 2020,
                                },
                                {
                                    j: 'AR',
                                    v: '86,49',
                                    a: 2021,
                                },
                                {
                                    j: 'AG',
                                    v: '97,49',
                                    a: 2020,
                                },
                                {
                                    j: 'BC',
                                    v: '92,85',
                                    a: 2021,
                                },
                                {
                                    j: 'BH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BN',
                                    v: '92,08',
                                    a: 2020,
                                },
                                {
                                    j: 'BT',
                                    v: '94,20',
                                    a: 2020,
                                },
                                {
                                    j: 'BR',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BV',
                                    v: '84,47',
                                    a: 2020,
                                },
                                {
                                    j: 'B',
                                    v: '96,97',
                                    a: 2021,
                                    customTitle: ' ',
                                },
                                {
                                    j: 'BZ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'CL',
                                    v: '74,34',
                                    a: 2021,
                                },
                                {
                                    j: 'CS',
                                    v: '82,62',
                                },
                                {
                                    j: 'CJ',
                                    v: '96,31',
                                    a: 2021,
                                },
                                {
                                    j: 'CT',
                                    v: '93,50',
                                    a: 2021,
                                },
                                {
                                    j: 'CV',
                                    v: '99,52',
                                },
                                {
                                    j: 'DB',
                                    v: '95,74',
                                    a: 2021,
                                },
                                {
                                    j: 'DJ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'GL',
                                    v: '96,24',
                                    a: 2020,
                                },
                                {
                                    j: 'GR',
                                    v: '95,00',
                                    a: 2021,
                                },
                                {
                                    j: 'GJ',
                                    v: '97,53',
                                },
                                {
                                    j: 'HR',
                                    v: '96,02',
                                },
                                {
                                    j: 'HD',
                                    v: '89,12',
                                    a: 2020,
                                },
                                {
                                    j: 'IL',
                                    v: '89,32',
                                    a: 2021,
                                },
                                {
                                    j: 'IS',
                                    v: '96,41',
                                    a: 2021,
                                },
                                {
                                    j: 'IF',
                                    v: '93,87',
                                    a: 2020,
                                    customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                                },
                                {
                                    j: 'MM',
                                    v: '90,36',
                                    a: 2021,
                                },
                                {
                                    j: 'MH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'MS',
                                    v: '96,71',
                                    a: 2020,
                                },
                                {
                                    j: 'NT',
                                    v: '94,18',
                                    a: 2021,
                                },
                                {
                                    j: 'OT',
                                    v: '97,23',
                                    a: 2020,
                                },
                                {
                                    j: 'PH',
                                    v: '96,99',
                                    a: 2021,
                                },
                                {
                                    j: 'SJ',
                                    v: '99,34',
                                    a: 2021,
                                },
                                {
                                    j: 'SM',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'SB',
                                    v: '91,78',
                                    a: 2021,
                                },
                                {
                                    j: 'SV',
                                    v: '97,80',
                                    a: 2020,
                                },
                                {
                                    j: 'TR',
                                    v: '99,73',
                                    a: 2020,
                                },
                                {
                                    j: 'TM',
                                    v: '98,38',
                                    a: 2021,
                                },
                                {
                                    j: 'TL',
                                    v: '97,94',
                                    a: 2020,
                                },
                                {
                                    j: 'VL',
                                    v: '94,98',
                                    a: 2021,
                                },
                                {
                                    j: 'VS',
                                    v: '87,45',
                                    a: 2021,
                                },
                                {
                                    j: 'VN',
                                    v: '95,67',
                                    a: 2021,
                                },
                            ],
                            //END: liceal urban

                            //START liceal rural
                            rural: [
                                {
                                    j: 'AB',
                                    v: '88,27',
                                    a: 2020,
                                },
                                {
                                    j: 'AR',
                                    v: '92,18',
                                    a: 2021,
                                },
                                {
                                    j: 'AG',
                                    v: '91,35',
                                    a: 2020,
                                },
                                {
                                    j: 'BC',
                                    v: '84,94',
                                    a: 2021,
                                },
                                {
                                    j: 'BH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BN',
                                    v: '90,13',
                                    a: 2020,
                                },
                                {
                                    j: 'BT',
                                    v: '93,20',
                                    a: 2020,
                                },
                                {
                                    j: 'BR',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'BV',
                                    v: '73,49',
                                    a: 2020,
                                },
                                {
                                    j: 'B',
                                    v: '',
                                    customTitle: " "
                                },
                                {
                                    j: 'BZ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'CL',
                                    v: '64,22',
                                    a: 2021,
                                },
                                {
                                    j: 'CS',
                                    v: '88,59',
                                },
                                {
                                    j: 'CJ',
                                    v: '92,52',
                                    a: 2021,
                                },
                                {
                                    j: 'CT',
                                    v: '94,86',
                                    a: 2021,
                                },
                                {
                                    j: 'CV',
                                    v: '99,52',
                                },
                                {
                                    j: 'DB',
                                    v: '89,70',
                                    a: 2021,
                                },
                                {
                                    j: 'DJ',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'GL',
                                    v: '96,21',
                                    a: 2020,
                                },
                                {
                                    j: 'GR',
                                    v: '79,33',
                                    a: 2021,
                                },
                                {
                                    j: 'GJ',
                                    v: '95,72',
                                },
                                {
                                    j: 'HR',
                                    v: '90,68',
                                },
                                {
                                    j: 'HD',
                                    v: '90,02',
                                    a: 2020,
                                },
                                {
                                    j: 'IL',
                                    v: '83,78',
                                    a: 2021,
                                },
                                {
                                    j: 'IS',
                                    v: '96,46',
                                    a: 2021,
                                },
                                {
                                    j: 'IF',
                                    v: '93,87',
                                    a: 2020,
                                },
                                {
                                    j: 'MM',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'MH',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'MS',
                                    v: '97,40',
                                    a: 2020,
                                },
                                {
                                    j: 'NT',
                                    v: '90,27',
                                    a: 2021,
                                },
                                {
                                    j: 'OT',
                                    v: '85,85',
                                    a: 2020,
                                },
                                {
                                    j: 'PH',
                                    v: '96,99',
                                    a: 2021,
                                },
                                {
                                    j: 'SJ',
                                    v: '96,25',
                                    a: 2021,
                                },
                                {
                                    j: 'SM',
                                    v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                },
                                {
                                    j: 'SB',
                                    v: '91,78',
                                    a: 2021,
                                },
                                {
                                    j: 'SV',
                                    v: '98,10',
                                    a: 2020,
                                },
                                {
                                    j: 'TR',
                                    v: '99,73',
                                    a: 2020,
                                },
                                {
                                    j: 'TM',
                                    v: '97,61',
                                    a: 2021,
                                },
                                {
                                    j: 'TL',
                                    v: '94,83',
                                    a: 2020,
                                },
                                {
                                    j: 'VL',
                                    v: '87,38',
                                    a: 2021,
                                },
                                {
                                    j: 'VS',
                                    v: '89,26',
                                    a: 2021,
                                },
                                {
                                    j: 'VN',
                                    v: '96,02',
                                    a: 2021,
                                },
                            ]
                            //END: liceal rural
                        }
                    ]  
                },
                //END: rata absolvire invatamant

                //START: rata promovare evaluare
                {
                    name: "rata_promovare_evaluare_nationala",
                    title: "Rată promovare Evaluare Națională",

                    //START: rata promovare urban
                    urban: [
                        {
                            j: 'AB',
                            v: '83,77',
                            a: 2020,
                        },
                        {
                            j: 'AR',
                            v: '79,86',
                            a: 2021,
                        },
                        {
                            j: 'AG',
                            v: '89,11',
                            a: 2021,
                        },
                        {
                            j: 'BC',
                            v: '86,27',
                            a: 2021,
                        },
                        {
                            j: 'BH',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'BN',
                            v: '86,44',
                            a: 2021,
                        },
                        {
                            j: 'BT',
                            v: '83,65',
                            a: 2021,
                        },
                        {
                            j: 'BR',
                            v: '90,28',
                            a: 2021,
                        },
                        {
                            j: 'BV',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'B',
                            v: '87,95',
                            a: 2021,
                            customTitle: ' ',
                        },
                        {
                            j: 'BZ',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'CL',
                            v: '65,20',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'CS',
                            v: '65,78',
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'CJ',
                            v: '91,24',
                            a: 2021,
                        },
                        {
                            j: 'CT',
                            v: '67,93',
                            a: 2021,
                        },
                        {
                            j: 'CV',
                            v: '84,62',
                            a: 2021,
                        },
                        {
                            j: 'DB',
                            v: '85,92',
                            a: 2021,
                        },
                        {
                            j: 'DJ',
                            v: '71,00',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'GL',
                            v: '88,78',
                            a: 2020,
                        },
                        {
                            j: 'GR',
                            v: '76,73',
                            a: 2021,
                        },
                        {
                            j: 'GJ',
                            v: '73,90',
                        },
                        {
                            j: 'HR',
                            v: '75,90',
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'HD',
                            v: '61,82',
                        },
                        {
                            j: 'IL',
                            v: '84,62',
                            a: 2021,
                        },
                        {
                            j: 'IS',
                            v: '90,83',
                            a: 2021,
                        },
                        {
                            j: 'IF',
                            v: '67,12',
                            a: 2020,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'MM',
                            v: '77,46',
                            a: 2021,
                        },
                        {
                            j: 'MH',
                            v: '69,63',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'MS',
                            v: '81,20',
                            
                        },
                        {
                            j: 'NT',
                            v: '84,84',
                            a: 2021,
                        },
                        {
                            j: 'OT',
                            v: '84,00',
                        },
                        {
                            j: 'PH',
                            v: '81,10',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'SJ',
                            v: '88,96',
                            a: 2021,
                        },
                        {
                            j: 'SM',
                            v: '83,39',
                            a: 2021,
                        },
                        {
                            j: 'SB',
                            v: '80,71',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'SV',
                            v: '88,00',
                            a: 2021,
                        },
                        {
                            j: 'TR',
                            v: '78,75',
                            a: 2021,
                        },
                        {
                            j: 'TM',
                            v: '83,98',
                            a: 2021,
                        },
                        {
                            j: 'TL',
                            v: '82,07',
                            a: 2020,
                        },
                        {
                            j: 'VL',
                            v: '85,46',
                            a: 2021,
                        },
                        {
                            j: 'VS',
                            v: '88,24',
                        },
                        {
                            j: 'VN',
                            v: '87,60',
                            a: 2021,
                        },
                    ],
                    //END: rata promovare urban

                    //START: rata promovare rural
                    rural: [
                        {
                            j: 'AB',
                            v: '65,73',
                            a: 2020,
                        },
                        {
                            j: 'AR',
                            v: '60,91',
                            a: 2021,
                        },
                        {
                            j: 'AG',
                            v: '62,20',
                            a: 2021,
                        },
                        {
                            j: 'BC',
                            v: '52,06',
                            a: 2021,
                        },
                        {
                            j: 'BH',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'BN',
                            v: '69,75',
                            a: 2021,
                        },
                        {
                            j: 'BT',
                            v: '55,81',
                            a: 2021,
                        },
                        {
                            j: 'BR',
                            v: '78,33',
                            a: 2021,
                        },
                        {
                            j: 'BV',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'CL',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'CS',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'CJ',
                            v: '63,24',
                            a: 2021,
                        },
                        {
                            j: 'CT',
                            v: '32,07',
                            a: 2021,
                        },
                        {
                            j: 'CV',
                            v: '59,69',
                            a: 2021,
                        },
                        {
                            j: 'DB',
                            v: '63,20',
                            a: 2021,
                        },
                        {
                            j: 'DJ',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'GL',
                            v: '69,70',
                            a: 2020,
                        },
                        {
                            j: 'GR',
                            v: '60,21',
                            a: 2021,
                        },
                        {
                            j: 'GJ',
                            v: '63,47',
                        },
                        {
                            j: 'HR',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'HD',
                            v: '38,56',
                        },
                        {
                            j: 'IL',
                            v: '57,53',
                            a: 2021,
                        },
                        {
                            j: 'IS',
                            v: '66,27',
                            a: 2021,
                        },
                        {
                            j: 'IF',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'MM',
                            v: '57,49',
                            a: 2021,
                        },
                        {
                            j: 'MH',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'MS',
                            v: '65,80',
                        },
                        {
                            j: 'NT',
                            v: '64,19',
                            a: 2021,
                        },
                        {
                            j: 'OT',
                            v: '62,88',
                        },
                        {
                            j: 'PH',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'SJ',
                            v: '61,76',
                            a: 2021,
                        },
                        {
                            j: 'SM',
                            v: '63,44',
                            a: 2021,
                        },
                        {
                            j: 'SB',
                            v: "",
                            customTitle: "",
                        },
                        {
                            j: 'SV',
                            v: '72,00',
                            a: 2021,
                        },
                        {
                            j: 'TR',
                            v: '54,03',
                            a: 2021,
                        },
                        {
                            j: 'TM',
                            v: '54,71',
                            a: 2021,
                        },
                        {
                            j: 'TL',
                            v: '57,60',
                            a: 2020,
                        },
                        {
                            j: 'VL',
                            v: '68,02',
                            a: 2021,
                        },
                        {
                            j: 'VS',
                            v: '59,03',
                        },
                        {
                            j: 'VN',
                            v: '63,98',
                            a: 2021,
                        },
                    ]
                    //END: rata promovare rural
                },
                //END: rata promovare evaluare

                //START: rata promovare bacalaureat
                {
                    name: "rata_promovare_bacalaureat",
                    title: "Rată promovare Bacalaureat",
                    
                    //START: rata promovare baca urban
                    urban: [
                        {
                            j: 'AB',
                            v: '52,88',
                            a: 2020,
                        },
                        {
                            j: 'AR',
                            v: '68,95',
                            a: 2021,
                        },
                        {
                            j: 'AG',
                            v: '72,59',
                            a: 2021,
                        },
                        {
                            j: 'BC',
                            v: '77,06',
                            a: 2021,
                        },
                        {
                            j: 'BH',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'BN',
                            v: '64,86',
                            a: 2021,
                        },
                        {
                            j: 'BT',
                            v: '74,00',
                            a: 2021,
                        },
                        {
                            j: 'BR',
                            v: '76,01',
                            a: 2021,
                        },
                        {
                            j: 'BV',
                            v: '75,99',
                            a: 2021,
                        },
                        {
                            j: 'B',
                            v: '74,35',
                            a: 2021,
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '76,28',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'CL',
                            v: '62,32',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'CS',
                            v: '60,75',
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'CJ',
                            v: '85,24',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'CT',
                            v: '65,76',
                            a: 2021,
                        },
                        {
                            j: 'CV',
                            v: '65,15',
                            a: 2021,
                        },
                        {
                            j: 'DB',
                            v: '74,43',
                            a: 2021,
                        },
                        {
                            j: 'DJ',
                            v: '71,70',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'GL',
                            v: '76,81',
                            a: 2020,
                        },
                        {
                            j: 'GR',
                            v: '55,35',
                            a: 2021,
                        },
                        {
                            j: 'GJ',
                            v: '51,12',
                        },
                        {
                            j: 'HR',
                            v: '61,76',
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'HD',
                            v: '57,88',
                        },
                        {
                            j: 'IL',
                            v: '61,95',
                            a: 2021,
                        },
                        {
                            j: 'IS',
                            v: '75,91',
                            a: 2021,
                        },
                        {
                            j: 'IF',
                            v: '39,42',
                            a: 2020,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'MM',
                            v: '66,93',
                            a: 2021,
                        },
                        {
                            j: 'MH',
                            v: '57,68',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'MS',
                            v: '71,40',
                        },
                        {
                            j: 'NT',
                            v: '77,45',
                            a: 2021,
                        },
                        {
                            j: 'OT',
                            v: '66,95',
                            a: 2021,
                        },
                        {
                            j: 'PH',
                            v: '72,85',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'SJ',
                            v: '67,44',
                            a: 2021,
                        },
                        {
                            j: 'SM',
                            v: '78,92',
                            a: 2021,
                        },
                        {
                            j: 'SB',
                            v: '82,60',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'SV',
                            v: '71,34',
                            a: 2021,
                        },
                        {
                            j: 'TR',
                            v: '58,72',
                            a: 2021,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'TM',
                            v: '73,42',
                            a: 2021,
                        },
                        {
                            j: 'TL',
                            v: '75,87',
                            a: 2021,
                        },
                        {
                            j: 'VL',
                            v: '69,06',
                            a: 2021,
                        },
                        {
                            j: 'VS',
                            v: '71,92',
                        },
                        {
                            j: 'VN',
                            v: '69,03',
                            a: 2021,
                        },
                    ],
                    //END: rata promovare baca urban

                    //START: rata promovare baca rural
                    rural: [
                        {
                            j: 'AB',
                            v: '34,53',
                            a: 2020,
                        },
                        {
                            j: 'AR',
                            v: '57,54',
                            a: 2021,
                        },
                        {
                            j: 'AG',
                            v: '47,14',
                            a: 2021,
                        },
                        {
                            j: 'BC',
                            v: '33,60',
                            a: 2021,
                        },
                        {
                            j: 'BH',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'BN',
                            v: '48,05',
                            a: 2021,
                        },
                        {
                            j: 'BT',
                            v: '48,76',
                            a: 2021,
                        },
                        {
                            j: 'BR',
                            v: '18,18',
                            a: 2021,
                        },
                        {
                            j: 'BV',
                            v: '44,30',
                            a: 2021,
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'CL',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'CS',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'CJ',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'CT',
                            v: '5,13',
                            a: 2021,
                        },
                        {
                            j: 'DB',
                            v: '59,89',
                            a: 2021,
                        },
                        {
                            j: 'DJ',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'GL',
                            v: '70,75',
                            a: 2020,
                        },
                        {
                            j: 'GR',
                            v: '34,14',
                            a: 2021,
                        },
                        {
                            j: 'GJ',
                            v: '19,96',
                        },
                        {
                            j: 'HR',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'HD',
                            v: '51,61',
                        },
                        {
                            j: 'IL',
                            v: '33,33',
                            a: 2021,
                        },
                        {
                            j: 'IS',
                            v: '56,47',
                            a: 2021,
                        },
                        {
                            j: 'IF',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'MM',
                            v: '13,04',
                            a: 2021,
                        },
                        {
                            j: 'MH',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'MS',
                            v: '60,20',
                        },
                        {
                            j: 'NT',
                            v: '58,62',
                            a: 2021,
                        },
                        {
                            j: 'OT',
                            v: '41,59',
                            a: 2021,
                        },
                        {
                            j: 'PH',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'SJ',
                            v: '37,35',
                            a: 2021,
                        },
                        {
                            j: 'SM',
                            v: '64,06',
                            a: 2021,
                        },
                        {
                            j: 'SB',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'SV',
                            v: '58,77',
                            a: 2021,
                        },
                        {
                            j: 'TR',
                            v: '',
                            a: '',
                            customTitle: '',
                        },
                        {
                            j: 'TM',
                            v: '64,98',
                            a: 2021,
                        },
                        {
                            j: 'TL',
                            v: '67,36',
                            a: 2021,
                        },
                        {
                            j: 'VL',
                            v: '41,42',
                            a: 2021,
                        },
                        {
                            j: 'VS',
                            v: '42,74',
                        },
                        {
                            j: 'VN',
                            v: '44,95',
                            a: 2021,
                        },
                    ]
                    //END: rata promocare baca rural

                },
                //END: rata promovare bacalaureat

                //START: grupuri sanitare neconforme
                {
                    name: "grupuri_sanitare_neconforme",
                    title: "Grupuri sanitare neconforme (în cadrul unităților școlare)",

                    //START: grupuri urban
                    urban: [
                        {
                            j: 'AB',
                            v: '5',
                            // i: 'fără apă curentă/canalizare',
                            a: 2020,
                        },
                        {
                            j: 'AR',
                            v: '0',
                            // i: 'din <span>51</span>'
                        },
                        {
                            j: 'AG',
                            v: '2',
                            // i: 'grup sanitar în exterior apă curentă și canalizare/fosă septică'
                        },
                        {
                            j: 'BC',
                            v: '1',
                            // i: 'în exterior, cu toate facilitățile'
                        },
                        {
                            j: 'BH',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'BN',
                            v: '0',
                        },
                        {
                            j: 'BT',
                            v: '0',
                        },
                        {
                            j: 'BR',
                            v: '0',
                            a: 2021,
                        },
                        {
                            j: 'BV',
                            v: '0',
                        },
                        {
                            j: 'B',
                            v: '0',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                            a: 2021,
                        },
                        {
                            j: 'CL',
                            v: '0',
                        },
                        {
                            j: 'CJ',
                            v: '0',
                            a: 2021,
                        },
                        {
                            j: 'CT',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                            a: 2021,
                        },
                        {
                            j: 'CV',
                            v: '6',
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'DB',
                            v: '0',
                        },
                        {
                            j: 'DJ',
                            v: 46,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'GL',
                            v:'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'GR',
                            v:'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'GJ',
                            v: '0',
                        },
                        {
                            j: 'HR',
                            v:'1',
                        },
                        {
                            j: 'HD',
                            v: '0',
                        },
                        {
                            j: 'IL',
                            v: 5,
                        },
                        {
                            j: 'IS',
                            v: '0',
                        },
                        {
                            j: 'IF',
                            v: '0',
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'MM',
                            v: '1',
                        },
                        {
                            j: 'MH',
                            v: '0',
                        },
                        {
                            j: 'MS',
                            v: '0',
                        },
                        {
                            j: 'NT',
                            v: "3",
                            // i: 'cu grup sanitar exterioare <span>1</span> fără canalizare'
                        },
                        {
                            j: 'OT',
                            v:'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'SJ',
                            v: '0',
                        },
                        {
                            j: 'SM',
                            v: '0',
                        },
                        {
                            j: 'SB',
                            v: '0',
                        },
                        {
                            j: 'SV',
                            v: '0',
                        },
                        {
                            j: 'TR',
                            v: 28,
                            customTitle: '<p style="font-size: 22px;font-weight: 700;">Date disponibile la nivelul întregului județ</p>',
                        },
                        {
                            j: 'TM',
                            v: '0',
                        },
                        {
                            j: 'TL',
                            v: '0',
                        },
                        {
                            j: 'VL',
                            v: 12,
                        },
                        {
                            j: 'VS',
                            v: '0',
                        },
                        {
                            j: 'VN',
                            v: '0',
                        },
                    ],
                    //END: grupuri urban

                    //START: grupuri rural
                    rural: [
                        {
                            j: 'AB',
                            v: '16',
                            // i: 'fără apă curentă <span>36</span> fără canalizare'
                        },
                        {
                            j: 'AR',
                            v: '6',
                            // i: 'din <span>297</span>'
                        },
                        {
                            j: 'AG',
                            v: '21',
                            // i: 'grup sanitar în exterior apă curentă și canalizare/fosă septică'
                        },
                        {
                            j: 'BC',
                            v: '6',
                        },
                        {
                            j: 'BH',
                            v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'BN',
                            v: '0',
                        },
                        {
                            j: 'BT',
                            v: '34',
                            // i: 'din <span>371</span>'
                        },
                        {
                            j: 'BR',
                            v: '3',
                            // i: 'din <span>158</span>'
                        },
                        {
                            j: 'BV',
                            v: '0',
                        },
                        {
                            j: 'B',
                            v: '',
                            customTitle: " "
                        },
                        {
                            j: 'BZ',
                            v: '14',
                            a: 2021,
                        },
                        {
                            j: 'CL',
                            v: '9',
                            // i: 'din <span>226</span>'
                        },
                        {
                            j: 'CS',
                            v: '5',
                        },
                        {
                            j: 'CJ',
                            v: '0',
                        },
                        {
                            j: 'CT',
                            v: '1',
                            // i: 'apă curentă, fără canalizare'
                        },
                        {
                            j: 'CV',
                            // v: '<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'DB',
                            v: '3',
                            // i: 'cu grup sanitar exterioare <span>10</span> fără apă curentă'
                        },
                        {
                            j: 'DJ',
                            v: '',
                            customTitle: ""
                        },
                        {
                            j: 'GL',
                            v: '96',
                            // i: 'cu grup sanitar exterioare, din care <span>2</span> fără apă curentă'
                        },
                        {
                            j: 'GR',
                            v: '6',
                            i: ''
                        },
                        {
                            j: 'GJ',
                            v: '1,08%',
                        },
                        {
                            j: 'HR',
                            v:'12',
                        },
                        {
                            j: 'HD',
                            v: '2,63%',
                        },
                        {
                            j: 'IL',
                            v: '12',
                        },
                        {
                            j: 'IS',
                            v: '58',
                            // i: 'cu grup sanitar exterioare, cu apă curentă'
                        },
                        {
                            j: 'IF',
                            v: '',
                            customTitle: ""
                        },
                        {
                            j: 'MM',
                            v: '26',
                        },
                        {
                            j: 'MH',
                            v: '59',
                            // i: 'cu grup sanitar exterioare <span>26</span> fără apă curentă <span>74</span> fără canalizare'
                        },
                        {
                            j: 'MS',
                            v: '16',
                        },
                        {
                            j: 'NT',
                            v: '24',
                            // i: 'cu grup sanitar exterioare <span>108</span> fără canalizare'
                        },
                        {
                            j: 'OT',
                            v:'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                        },
                        {
                            j: 'PH',
                            v: '8',
                            // i: 'din <span>621</span> cu apă curentă'
                        },
                        {
                            j: 'SJ',
                            v: '6',
                        },
                        {
                            j: 'SM',
                            v: '0',
                        },
                        {
                            j: 'SB',
                            v: '4',
                            // i: 'cu grup sanitar exterior, din care <span>2</span> fără apă curentă și canalizare',
                        },
                        {
                            j: 'SV',
                            v: '12',
                        },
                        {
                            j: 'TR',
                            v: '',
                            customTitle: ""
                        },
                        {
                            j: 'TM',
                            v: '0',
                        },
                        {
                            j: 'TL',
                            v: '2',
                        },
                        {
                            j: 'VL',
                            v: '43',
                        },
                        {
                            j: 'VS',
                            v: '199',
                        },
                        {
                            j: 'VN',
                            v: '73',
                        },
                    ]
                    //END: grupuri rural
                }
                //END: grupuri sanitare neconforme
            ]

        }
        //END:Educatie

    ],
};

export default data;