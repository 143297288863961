import jqueryStuff from './jquery-stuff';
import AppMap from './vue-apps/app-map';

if (AppMap.appExists) {
    AppMap.load(jqueryStuff);
}
else {
    $(document).ready(function() {
        jqueryStuff();
    });
}
