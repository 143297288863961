export default function () {

    console.log('executing jqueryStuff');
    //vars
    var btn_menu = $('.btn--menu-js'),
        hero_banner = $('.hero--banner-js'),
        hero_statistics = $('.hero--statistics-js'),
        hero_texts = $('.hero--texts-js');
    //end vars


    //menu
    if (btn_menu.length) {
        btn_menu.on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('html').toggleClass('body-menu-active');
        });
    }
    //end menu
    

    //hero banner
    if (hero_banner.length) {
        hero_banner.on('init', function(event, slick){
            slick.$dots.addClass('hero--dots');
        });
        hero_banner.slick({
            fade: true,
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 4000
        });   
    }
    //end hero banner

    //scroll minus - scroll to
    if($(window).width() <= 767) {
        var scrollminus = 50;
    } else {
        var scrollminus = 20;
    }
    //end scroll minus - scroll to
    
    //scroll to categories
    function scrollTabs() {
        $('html, body').animate({
            scrollTop: $('.categories-js').offset().top -scrollminus + 'px'
        }, 500);
    }
    $('.scrollto-cat-js').on('click', function() {
        scrollTabs();
    });
    //end scroll to categories
    
    //hero statistics
    function heroStatistics() {
        hero_statistics.on('init', function(event, slick){
            slick.$dots.addClass('hero--dots');
        });
        hero_statistics.slick({
            fade: true,
            arrows: false,
            dots: true,
        });
    }
    if (hero_statistics.length) {    
        //$(window).on('load', function() {
            setTimeout(function() {
                heroStatistics();
            }, 10)

        //});
    }
    //end hero statistics

    //drop down menu mobile
    if ($('.wrapper-dropdown-xs').length) {
        if($(window).width() <= 767) {
            function DropDown(el) {
                this.dd = el;
                this.placeholder = this.dd.children('span');
                this.opts = this.dd.find('ul.dropdown > li');
                this.val = '';
                this.index = -1;
                this.initEvents();
            }
            DropDown.prototype = {
                initEvents : function() {
                    var obj = this;
        
                    obj.dd.on('click', function(event){
                        $(this).toggleClass('active');
                        return false;
                    });
        
                    obj.opts.on('click',function(){
                        var opt = $(this);
                        obj.val = opt.text();
                        obj.index = opt.index();
                        obj.placeholder.text(obj.val);
                    });
                },
                getValue : function() {
                    return this.val;
                },
                getIndex : function() {
                    return this.index;
                }
            }
        
            $(function() {
        
                var dd = new DropDown( $('#ddxs') );
        
                $(document).click(function() {
                    // all dropdowns
                    $('.wrapper-dropdown-xs').removeClass('active');
                });
        
            });
        }
    }
    //end drop down menu mobile


    //tabs group home
    if ($('.tabs-group-js').length) {
        $('.tabs-group-js').each(function() {

            var maintabs_lists = $('li', $(this)),
                maintabs_anchors = $('a', maintabs_lists),
                maintabs, selector = '';

            maintabs_anchors.each(function(index) {

                selector += ($(this).attr('href') != '#' ? (selector !== '' ? ', ' : '') + $(this).attr('href') : '');

                $(this).on('click', function(e) {
                    e.preventDefault();
                    hero_statistics.slick('unslick');
                    document.location.hash = $(this).attr('href');
                    maintabs_lists.removeClass('current-menu-item');
                    $(maintabs_lists[index]).addClass('current-menu-item');
                    maintabs.css('display', 'none').removeClass('active');
                    $(maintabs[index]).removeClass('hidden').css('display', 'block').addClass('active');
                    heroStatistics();
                });

            });

            maintabs = $(selector);

            maintabs.easyResponsiveTabs({
                type: 'vertical',
                width: 'auto',
                fit: true,
                closed: 'accordion',
                activate: function() {
                    document.location.hash = '';
                }
            });

            maintabs.css('display', 'none').removeClass('active');
            $(maintabs[0]).css('display', 'block').addClass('active');

            //refresh sau deschide tab nou cu o anumita categorie activa
            if (document.location.hash !== '') {
                setTimeout(function() {
                    var active_tab = document.location.hash.replace(/[0-9]+$/, '');
                    //console.log(active_tab);
                    if ($(active_tab).length > 0) {
                        scrollTabs();
                        maintabs_lists.removeClass('current-menu-item');
                        maintabs.css('display', 'none').removeClass('active');
                        $('[href="' + active_tab + '"]', maintabs_lists).parents('li').addClass('current-menu-item');
                        $(active_tab).removeClass('hidden').css('display', 'block').addClass('active'); 
                        $('#ddxs span').text($('[href="' + active_tab + '"]', maintabs_lists).text());
                    }
                }, 1);
            }

        });
    }
    //end tabs group home



    //hero texts
    if (hero_texts.length) {
        hero_texts.slick({
            arrows: true,
            prevArrow: $('.btn--hero-prev-js'),
            nextArrow: $('.btn--hero-next-js'),
        });
    }
    //end hero texts


    // $.fn.randomize = function (selector) {
    //     var $elems = selector ? $(this).find(selector) : $(this).children(),
    //         $parents = $elems.parent();
    
    //     $parents.each(function () {
    //         $(this).children(selector).sort(function (childA, childB) {
    //             // * Prevent last slide from being reordered
    //             if($(childB).index() !== $(this).children(selector).length - 1) {
    //                 return Math.round(Math.random()) - 0.5;
    //             }
    //         }.bind(this)).detach().appendTo(this);
    //     });
    
    //     return this;
    // };
    
    // hero_texts.randomize().slick();



    //read more
    if ($('.btn--more-js').length) {
        $('.btn--more-js').on('click', function() {
            $('.despre--texts-js').toggleClass('show');
            $(this).toggleClass('show');
        });
    }
    

    //tooltip
    $('[data-toggle="tooltip"]').tooltip();


    // Custom select for map 
    // $('select').each(function(){
    //     var $this = $(this), numberOfOptions = $(this).children('option').length;
      
    //     $this.addClass('select-hidden'); 
    //     $this.wrap('<div class="select"></div>');
    //     $this.after('<div class="select-styled"></div>');
    
    //     var $styledSelect = $this.next('div.select-styled');
    //     $styledSelect.text($this.children('option').eq(0).text());
      
    //     var $list = $('<ul />', {
    //         'class': 'select-options'
    //     }).insertAfter($styledSelect);
      
    //     for (var i = 0; i < numberOfOptions; i++) {
    //         $('<li />', {
    //             text: $this.children('option').eq(i).text(),
    //             rel: $this.children('option').eq(i).val()
    //         }).appendTo($list);
    //         if ($this.children('option').eq(i).is(':selected')){
    //          $('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected')
    //         }
    //     }
      
    //     var $listItems = $list.children('li');
      
    //     $styledSelect.click(function(e) {
    //         e.stopPropagation();
    //         $('div.select-styled.active').not(this).each(function(){
    //             $(this).removeClass('active').next('ul.select-options').hide();
    //         });
    //         $(this).toggleClass('active').next('ul.select-options').toggle();
    //     });
      
    //     $listItems.click(function(e) {
    //         e.stopPropagation();
    //         $styledSelect.text($(this).text()).removeClass('active');
    //         $this.val($(this).attr('rel'));
    //         $list.hide();
    //         //console.log($this.val());
    //     });
      
    //     $(document).click(function() {
    //         $styledSelect.removeClass('active');
    //         $list.hide();
    //     });
    
    // });
    
    // $('select').wrap('<div class="select__wrapper"></div>')
    // $('select').parent().prepend('<span>'+ $(this).find(':selected').text() +'</span>');

    // $('select').css('display', 'none');					
    // $('select').parent().append('<ul class="select__inner"></ul>');
    // $('select').children().each(function(){
    //     var opttext = $(this).text();
    //     var optval = $(this).val();
    //     $('select').parent().children('.select__inner').append('<li id="' + optval + '">' + opttext + '</li>');
    // });

    // $('select').parent().find('li').on('click', function (){
    //     var cur = $(this).attr('id');
    //     $('select').parent().children('span').text($(this).text());
    //     $('select').children().removeAttr('selected');
    //     $('select').children('[value="'+cur+'"]').attr('selected','selected');					
    //     $('select').parent().removeClass('openSelect');
    //     $('select').parent().find('ul').hide();
    // });

    // $('select').parent().find('span').on('click', function (){
    //     $('select').parent().find('ul').slideToggle(200);
    //     $('select').parent().toggleClass('openSelect');
    // });

}