import Vue from 'vue';
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
import phase_2_data from './phase-2-data';

var appId = '#app-map';
var appExists = !!document.querySelector(appId);
var noop = function () {};

export default {
    appExists,
    load(callback = noop) {
        if (appExists) {
            new Vue({
                el: appId,
                name: 'AppMap',
                data () {
                    return {
                        phase_2_data,
                        selectedCategoryName: null,
                        selectedSubCategory1Name: null,
                        selectedSubCategory2Name: null,
                        hoveredCounty: null,
                        activeCounty: null,
                        activeMobileCounty: 'placeholder',
                        selectedCategoryColorName: '',
                        // Hovered county position
                        hCountyPosX: null,
                        hCountyPosY: null,
                        popupX: 0,
                        popupY: 0,
                        showPopup: false,
                        // Selected county values
                        selectDataRural: '',
                        selectDataUrban: '',
                        // If data has more info
                        hasInfo: false,
                        // Set colors
                        accentColor: '',
                        // Lock Map
                        lockMap: true,
                        // Category images
                        selectedCatImage: '',
                        // Mobile Counties
                        counties: [
                            {
                                name: 'Alba',
                                iso: "AB"
                            },
                            {
                                name: 'Arad',
                                iso: "AR"
                            },
                            {
                                name: 'Argeș',
                                iso: "AG"
                            },
                            {
                                name: 'Bacău',
                                iso: "BC"
                            },
                            {
                                name: 'Bihor',
                                iso: "BH"
                            },
                            {
                                name: 'Bistrița-Năsăud',
                                iso: "BN"
                            },
                            {
                                name: 'Botoșani',
                                iso: "BT"
                            },
                            {
                                name: 'Brăila',
                                iso: "BR"
                            },
                            {
                                name: 'Brașov',
                                iso: "BV"
                            },
                            {
                                name: 'Buzău',
                                iso: "BZ"
                            },
                            {
                                name: 'București',
                                iso: "B"
                            },
                            {
                                name: 'Călărași',
                                iso: "CL"
                            },
                            {
                                name: 'Caraș-Severin',
                                iso: "CS"
                            },
                            {
                                name: 'Cluj',
                                iso: "CJ"
                            },
                            {
                                name: 'Constanța',
                                iso: "CT"
                            },
                            {
                                name: 'Covasna',
                                iso: "CV"
                            },
                            {
                                name: 'Dâmbovița',
                                iso: "DB"
                            },
                            {
                                name: 'Dolj',
                                iso: "DJ"
                            },
                            {
                                name: 'Galați',
                                iso: "GL"
                            },
                            {
                                name: 'Giurgiu',
                                iso: "GR"
                            },
                            {
                                name: 'Gorj',
                                iso: "GJ"
                            },
                            {
                                name: 'Harghita',
                                iso: "HR"
                            },
                            {
                                name: 'Hunedoara',
                                iso: "HD"
                            },
                            {
                                name: 'Ialomița',
                                iso: "IL"
                            },
                            {
                                name: 'Iași',
                                iso: "IS"
                            },
                            {
                                name: 'Ilfov',
                                iso: "IF"
                            },
                            {
                                name: 'Maramureș',
                                iso: "MM"
                            },
                            {
                                name: 'Mehedinți',
                                iso: "MH"
                            },
                            {
                                name: 'Mureș',
                                iso: "MS"
                            },
                            {
                                name: 'Neamț',
                                iso: "NT"
                            },
                            {
                                name: 'Olt',
                                iso: "OT"
                            },
                            {
                                name: 'Prahova',
                                iso: "PH"
                            },
                            {
                                name: 'Sălaj',
                                iso: "SJ"
                            },
                            {
                                name: 'Satu Mare',
                                iso: "SM"
                            },
                            {
                                name: 'Sibiu',
                                iso: "SB"
                            },
                            {
                                name: 'Suceava',
                                iso: "SV"
                            },
                            {
                                name: 'Teleorman',
                                iso: "TR"
                            },
                            {
                                name: 'Tulcea',
                                iso: "TL"
                            },
                            {
                                name: 'Timiș',
                                iso: "TM"
                            },
                            {
                                name: 'Vâlcea',
                                iso: "VL"
                            },
                            {
                                name: 'Vaslui',
                                iso: "VS"
                            },
                            {
                                name: 'Vrancea',
                                iso: "VN"
                            }
                        ],
                    }
                },
                computed: {
                    selectData() {
                        if(this.hoveredCounty && this.selectedCategory) {
                            if( this.selectedCategory.name === 'populatie_generala') {
                                return {
                                    urban: this.selectedCategory.urban.find(value=>value.j === this.hoveredCounty).v,
                                    rural: this.selectedCategory.rural.find(value=>value.j === this.hoveredCounty).v,
                                    customTitle: this.selectedCategory.rural.find(value=>value.j === this.hoveredCounty)?.customTitle||'',
                                }
                            }
                            if( this.selectedCategory.name === 'saracie_si_excluziune_sociala' && this.selectedSubCategory1Name) {
                                const subcategory = this.selectedCategory.subcategories.find(sub=>sub.name === this.selectedSubCategory1Name.name);
                                return {
                                    urban: subcategory.urban.find(value=>value.j === this.hoveredCounty).v,
                                    rural: subcategory.rural.find(value=>value.j === this.hoveredCounty).v,
                                    customTitle: subcategory.rural.find(value=>value.j === this.hoveredCounty)?.customTitle||'',
                                }
                            }
                            if( this.selectedCategory.name === 'sanatate' && this.selectedSubCategory1Name) {
                                const subcategory = this.selectedCategory.subcategories.find(sub=>sub.name === this.selectedSubCategory1Name.name);
                                if(subcategory.subcategories?.length)
                                {
                                    const subcategory2 = subcategory.subcategories.find(sub=>sub.name === this.selectedSubCategory2Name.name);
                                    return {
                                        urban: subcategory2.urban.find(value=>value.j === this.hoveredCounty)?.v||'<span style="font-size: 14px;">Datele nu sunt disponibile.</span>',
                                        rural: subcategory2.rural.find(value=>value.j === this.hoveredCounty)?.v||'<span style="font-size: 14px;">Datele nu sunt disponibile.</span>',
                                        customTitle: subcategory2.rural.find(value=>value.j === this.hoveredCounty)?.customTitle||'',
                                        urbanInfo: subcategory2.urban.find(value=>value.j === this.hoveredCounty)?.i||'',
                                    }
                                }else{
                                return {
                                    urban: subcategory.urban.find(value=>value.j === this.hoveredCounty)?.v||'<span style="font-size: 14px;">Datele nu sunt disponibile.</span>',
                                    urbanInfo: subcategory.urban.find(value=>value.j === this.hoveredCounty)?.i||'',
                                    rural: subcategory.rural.find(value=>value.j === this.hoveredCounty)?.v||'<span style="font-size: 14px;">Datele nu sunt disponibile.</span>',
                                    ruralInfo: subcategory.rural.find(value=>value.j === this.hoveredCounty)?.i||'',
                                    customTitle: subcategory.rural.find(value=>value.j === this.hoveredCounty)?.customTitle||'',
                                }
                            }
                            }
                            if( this.selectedCategory.name === 'educatie' && this.selectedSubCategory1Name) {
                                const subcategory = this.selectedCategory.subcategories.find(sub=>sub.name === this.selectedSubCategory1Name.name);

                                if(subcategory.subcategories?.length)
                                {
                                    const subcategory2 = subcategory.subcategories.find(sub=>sub.name === this.selectedSubCategory2Name.name);
                                    return {
                                        // urban: subcategory2.urban.find(value=>value.j === this.hoveredCounty)?.v||'0',
                                        urban: subcategory2.urban.find(value=>value.j === this.hoveredCounty)?.v||'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                        urbanYear: subcategory2.urban.find(value=>value.j === this.hoveredCounty)?.a||'',
                                        rural: subcategory2.rural.find(value=>value.j === this.hoveredCounty)?.v||'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                        ruralYear: subcategory2.rural.find(value=>value.j === this.hoveredCounty)?.a||'',
                                        customTitle: subcategory2.urban.find(value=>value.j === this.hoveredCounty)?.customTitle||'',
                                    }
                                }else {
                                    return {
                                        urban: subcategory.urban.find(value=>value.j === this.hoveredCounty)?.v||'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                        urbanYear: subcategory.urban.find(value=>value.j === this.hoveredCounty)?.a||'',
                                        urbanInfo: subcategory.urban.find(value=>value.j === this.hoveredCounty)?.i||'',
                                        rural: subcategory.rural.find(value=>value.j === this.hoveredCounty)?.v||'<p style="font-size: 14px;">Datele nu sunt disponibile.</p>',
                                        ruralYear: subcategory.rural.find(value=>value.j === this.hoveredCounty)?.a||'',
                                        ruralInfo: subcategory.rural.find(value=>value.j === this.hoveredCounty)?.i||'',
                                        customTitle: subcategory.urban.find(value=>value.j === this.hoveredCounty)?.customTitle||'',
                                    }
                                }
                            }
                        }

                        return {
                            urban: 'Datele nu sunt disponibile.',
                            rural:  'Datele nu sunt disponibile.'
                        }
                    },
                  
                    selectedCategory() {
                        // console.log("FIRST")
                        var selectedCategory = null;
                        this.selectedSubCategory1Name = null;
                        this.selectedSubCategory2Name = null;
                        if (this.selectedCategoryName) {
                            selectedCategory = this.phase_2_data.categories.find(c => c.name === this.selectedCategoryName.name);
                        }
                        const options = ['saracie_si_excluziune_sociala','sanatate','educatie']
                        if(options.includes(selectedCategory?.name)) {
                            if(!this.selectedSubCategory1 ) {
                                this.lockMap = true;
                            }
                        }else if(selectedCategory){
                            this.lockMap = false
                        }
                        return selectedCategory;
                    },
                    selectedSubcategory1 () {
                        this.selectedSubCategory2Name = null
                        var selectedSubcategory1 = null;
                        if (this.selectedSubCategory1Name && this.selectedCategory) {
                            selectedSubcategory1 = this.selectedCategory.subcategories.find(sc => sc.name === this.selectedSubCategory1Name.name);
                        }
                        if(selectedSubcategory1?.subcategories?.length ) {
                            this.lockMap = true;
                        }else if(selectedSubcategory1){
                            this.lockMap = false;
                        }
                        return selectedSubcategory1;
                    },
                    selectedSubcategory2 () {
                        var selectedSubcategory2 = null;
                        if (this.selectedSubCategory2Name && this.selectedCategory) {
                            selectedSubcategory2 = this.selectedSubcategory1.subcategories.find(sc => sc.name === this.selectedSubCategory2Name.name);
                        }
                        if(selectedSubcategory2){
                            this.lockMap = false;

                        } 
                        if(!this.selectedCategory){
                            this.lockMap = true;
                        }
                        return selectedSubcategory2;
                    },                    
                },
                methods: {
                    ddOpened() {
                        // this.lockMap = true;
                    },
                    ddClosed() {
                        // this.lockMap = false;
                    },
                    ddClosedLast() {
                        this.lockMap = false;
                    },
                    ddSelected(selectedOption) {
                        // this.lockMap = false;
                        // console.log(selectedOption.name);
                        if(
                            selectedOption.name === 'saracie_si_excluziune_sociala' || 
                            selectedOption.name === 'nr_pers_ajutor_social' ||
                            selectedOption.name === 'nr_someri' ||
                            selectedOption.name === 'nr_emigranti_temporar'
                        ) {
                            this.accentColor = 'red';
                        } else {
                            this.accentColor = 'blue';
                        }

                        if(selectedOption.name === 'primar' || selectedOption.name === 'gimnazial' || selectedOption.name === 'liceal') {
                            this.selectedCatImage = 'rata_promovare_evaluare_nationala';
                        } else if (selectedOption.name === 'rata_mortalitate_2020') {
                            this.selectedCatImage = 'rata_mortalitate_generala_2020';
                        } else if (selectedOption.name === 'spitale' || selectedOption.name === 'policlinici' || selectedOption.name === 'cabinete_independente') {
                            this.selectedCatImage = 'nr_unitati_sanitare';
                        } else if (selectedOption.name === 'medici_de_familie') {
                            this.selectedCatImage = 'nr_medici';
                        } else {
                            this.selectedCatImage = selectedOption.name;
                        }


                    },
                    ddCountySelected(selectedOption) {
                        // this.lockMap = false;
                        this.activeMobileCounty = `m-${selectedOption.name}`;
                        this.hoveredCounty = selectedOption.iso;
                        this.setActiveCounty();
                        // console.log(selectedOption);
                    },
                    ddDeselected() {
                        // this.lockMap = true;
                    },
                    setActiveCounty() {
                        let aCounty = '';
                        if(this.hoveredCounty === 'CT') {
                            aCounty = 'Constanța'
                        } else if(this.hoveredCounty === 'AB') {
                            aCounty = 'Alba';
                        } else if(this.hoveredCounty === 'AR') {
                            aCounty = 'Arad'
                        } else if(this.hoveredCounty === 'AG') {
                            aCounty = 'Argeș'
                        } else if(this.hoveredCounty === 'BC') {
                            aCounty = 'Bacău'
                        } else if(this.hoveredCounty === 'BH') {
                            aCounty = 'Bihor'
                        } else if(this.hoveredCounty === 'BN') {
                            aCounty = 'Bistrița-Năsăud'
                        } else if(this.hoveredCounty === 'BT') {
                            aCounty = 'Botoșani'
                        } else if(this.hoveredCounty === 'BR') {
                            aCounty = 'Brăila'
                        } else if(this.hoveredCounty === 'BV') {
                            aCounty = 'Brașov'
                        } else if(this.hoveredCounty === 'BZ') {
                            aCounty = 'Buzău'
                        } else if(this.hoveredCounty === 'CL') {
                            aCounty = 'Călărași'
                        } else if(this.hoveredCounty === 'CS') {
                            aCounty = 'Caraș-Severin'
                        } else if(this.hoveredCounty === 'CJ') {
                            aCounty = 'Cluj'
                        } else if(this.hoveredCounty === 'CV') {
                            aCounty = 'Covasna'
                        } else if(this.hoveredCounty === 'DB') {
                            aCounty = 'Dâmbovița'
                        } else if(this.hoveredCounty === 'DJ') {
                            aCounty = 'Dolj'
                        } else if(this.hoveredCounty === 'GL') {
                            aCounty = 'Galați'
                        } else if(this.hoveredCounty === 'GR') {
                            aCounty = 'Giurgiu'
                        } else if(this.hoveredCounty === 'GJ') {
                            aCounty = 'Gorj'
                        } else if(this.hoveredCounty === 'HR') {
                            aCounty = 'Harghita'
                        } else if(this.hoveredCounty === 'HD') {
                            aCounty = 'Hunedoara'
                        } else if(this.hoveredCounty === 'IL') {
                            aCounty = 'Ialomița'
                        } else if(this.hoveredCounty === 'IS') {
                            aCounty = 'Iași'
                        } else if(this.hoveredCounty === 'IF') {
                            aCounty = 'Ilfov'
                        } else if(this.hoveredCounty === 'MM') {
                            aCounty = 'Maramureș'
                        } else if(this.hoveredCounty === 'MH') {
                            aCounty = 'Mehedinți'
                        } else if(this.hoveredCounty === 'MS') {
                            aCounty = 'Mureș'
                        } else if(this.hoveredCounty === 'NT') {
                            aCounty = 'Neamț'
                        } else if(this.hoveredCounty === 'OT') {
                            aCounty = 'Olt'
                        } else if(this.hoveredCounty === 'PH') {
                            aCounty = 'Prahova'
                        } else if(this.hoveredCounty === 'SJ') {
                            aCounty = 'Sălaj'
                        } else if(this.hoveredCounty === 'SM') {
                            aCounty = 'Satu Mare'
                        } else if(this.hoveredCounty === 'SB') {
                            aCounty = 'Sibiu'
                        } else if(this.hoveredCounty === 'SV') {
                            aCounty = 'Suceava'
                        } else if(this.hoveredCounty === 'TR') {
                            aCounty = 'Teleorman'
                        } else if(this.hoveredCounty === 'TL') {
                            aCounty = 'Tulcea'
                        } else if(this.hoveredCounty === 'TM') {
                            aCounty = 'Timiș'
                        } else if(this.hoveredCounty === 'VL') {
                            aCounty = 'Vâlcea'
                        } else if(this.hoveredCounty === 'VS') {
                            aCounty = 'Vaslui'
                        } else if(this.hoveredCounty === 'VN') {
                            aCounty = 'Vrancea'
                        } else if (this.hoveredCounty === 'B') {
                            aCounty = 'București'
                        }

                        return this.activeCounty = aCounty;
                    },
                    onMouseEnter (e, hoveredCounty) {
                        var target = e.target;
                        var data = target.dataset;
                        var x = data.x;
                        var y = data.y;
                        // console.log(target, data);
                        this.hoveredCounty = hoveredCounty;
                        this.popupX = x;
                        this.popupY = y;
                        if(this.selectedCategoryName == null) {
                            this.showPopup = false;
                        } else {
                            this.showPopup = true;
                        };
                        this.setActiveCounty();
                    },
                    onMouseLeave () {
                        this.showPopup = false;
                        this.hoveredCounty = null;
                        this.activeCounty = null;
                    },  
                },
                watch: {
                    // immediate: true,               
                    accentColor(newVal) {
                        if(newVal === 'red') {
                            document.body.classList.add('accentColor__text--red', 'accentColor__bg--red');
                            document.body.classList.remove('accentColor__text--blue', 'accentColor__bg--blue');
                        } else if (newVal === 'blue') {
                            document.body.classList.add('accentColor__text--blue', 'accentColor__bg--blue');
                            document.body.classList.remove('accentColor__text--red', 'accentColor__bg--red');
                        }
                        // console.log(newVal);
                    },
                },
                mounted () {
                    callback();
                }
            });
        }
    }
}
